import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { OPTION_DAYS } from "../../../../constants/options";
import { format, isWithinInterval } from "date-fns";
import moment from "moment";

import STATES from "../../ApplicationForm/states.json";


// ICON
import CalendarIcon from "../../../../constants/icons/calendar-input.svg";
import ClockIcon from "../../../../constants/icons/clock.svg";

const EventDetailsContainer = styled.div`

    display: flex;
    flex-direction: column;
    background-color #F4F4F5;
    color: #6c6c6c;
    padding: 24px;
    border-radius: 10px;


    .event-type-container {
        display: flex;
        flex-direction: row;
    }

    .event-type-container > div:nth-child(2),  .event-type-container > div:nth-child(3)  {
        margin-left: 12px;
    }

    input[type=radio] {
        width: 17px;
        height: 17px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid rgba(220,220,225,1);
        accent-color: #1B0B03;
    }

    
    input[type=checkbox] {
        width: 17px;
        height: 17px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid rgba(220,220,225,1);
    }


    .event-checkbox-container {
        position: relative;
        display: flex;
        align-items: center;
    }

    .event-checkbox-container input, .event-checkbox-container span{
        position: relative;
    }
    
    
    .event-input {
        padding: 8px 16px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    .event-input-textarea {
        resize: none;
        width: 97%;
    }

    .event-input-container {
        margin-top: 12px;
        margin-bottom: 12px;
    }

     .event-group-container{
        display: grid;
        grid-template-columns: 20% 40% 40%;
        width: 100%;
        grid-gap: 8px;
     }

     .event-group-container > div {
        width: 100%;
     }

     .event-group-container > div > select {
        width: 97%;
     }
      

      .event-primary-details-container {
        display: grid !important;
        grid-template-columns: 50% 23% 23%;
        width: 100% !important;
        grid-gap: 8px !important;
      }

      .event-date-container {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      
      .event-date-container > div:not(:first-child) {
        margin-left: 5px;
      }
      .event-primary-details-container > div  {
        width: 100%;
      }

   
      .event-label {
        height: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .event-primary-details-container > div > input {
        width: 100%;
        position: relative;
        box-sizing: border-box;
      }

      .event-date-picker {
        width: 150px;
        height:20px;
        margin-left: -10px;
      }

      .event-time-picker {
        margin-left: -10px;
      }

      .event-time-picker {
        width: 80px !important;
        height: 20px;
      }
      
      .event-time-picker {
        width: 100px;
        height:20px;
      }
      

      .input-item {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 10px;
      }
    
      .input-item img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #6c6c6c;
      }

      .event-type-label {
        position: relative;
        top: -3px;
        color: #1B0B03;
        font-size: 16px;
        font-weight: 400;
      }

      .date-filter-container {
        display: flex;
        gap: 16px;
      }

    
      @media (max-width: 768px) {
        .date-filter-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }


        .event-primary-details-container {
          grid-template-columns: 1fr;
        }

        .event-group-container {
            grid-template-columns: 1fr;
        }

        .event-date-container {
            display: flex;
            flex-direction: column;
        }
      }

`;

const DATE_FORMAT = "MMM d, yyyy";

const DateCustomInput = ({
  value,
  onClick,
  name,
  className,
  placeholder,
  label,
}) => (
  <div className="input-item">
    <input
      value={value}
      onClick={onClick}
      name={name}
      className={className}
      placeholder={DATE_FORMAT}
      readOnly={true}
      id={`attendance_date`}
    />
    <img src={CalendarIcon} />
  </div>
);

// const TimeCustomInput = ({
//     value,
//     onClick,
//     name,
//     className,
//     placeholder,
//     register,
//     label,
//   }) => {
//     return (
//       <div className="field">
//         <input
//           value={value}
//           onClick={onClick}
//           name={name}
//           className={className}
//           placeholder={"HH:mm"}
//           readOnly={true}
//           id={`attendance_time`}
//           style={{
//             width: "100%",
//           }}
//         />
//         <label className="field-label" for={`attendance_date`}>
//           {label}
//         </label>
//         <FontAwesomeIcon icon={faClock} className="calendar-icon" />
//       </div>
//     );
//   };

const TimeCustomInput = ({ value, onClick, name, className }) => {
  return (
    <div className="input-item">
      <input
        value={value}
        name={name}
        onClick={onClick}
        className={`${className} event-time-picker event-input`}
        placeholder="h:mm aa"
        readOnly={true}
      />
      <img src={ClockIcon} />
    </div>
  );
};

const EventDetailsInput = ({
  forms = [],
  groups = [],
  eventDetails,
  handleEventDetailChange,
  handleSelectFormChange,
  handleSelectGroupChange,
  handleTimeChange,
  selectedForm,
  selectedGroup,
  register,
  setCurrentEvent,
  vendors = [],
}) => {
  const [adminVendor, setAdminVendor] = useState([]);

  useEffect(() => {
    setAdminVendor(vendors);
  }, [vendors]);

  console.log('eventDetails', eventDetails)

  return (
    <EventDetailsContainer>
      {/* <div className="event-input-container event-type-container">
        <div>
          <input
            checked={eventDetails?.event_type === "single_day"}
            onChange={handleEventDetailChange}
            type="radio"
            name="event_type"
            value="single_day"
          />{" "}
          <span className="event-type-label">Single-Day</span>
        </div>
        <div>
          <input
            checked={eventDetails?.event_type === "multi_day"}
            onChange={handleEventDetailChange}
            type="radio"
            name="event_type"
            value="multi_day"
          />{" "}
          <span className="event-type-label">Multi-Day</span>
        </div>
        <div>
          <input
            checked={eventDetails?.event_type === "recurring"}
            onChange={handleEventDetailChange}
            type="radio"
            name="event_type"
            value="recurring"
          />{" "}
          <span className="event-type-label">Recurring</span>
        </div>
      </div> */}

      <div>
        <div className="event-label">Form</div>
        <select
          disabled={true}
          value={selectedForm}
          onChange={handleSelectFormChange}
          className="event-input"
          placeholder="Form"
        >
          <option value="">Please Select Form</option>
          <option value="mentoring">Mentoring</option>
          {forms.map((form) => {
            return (
              <option key={form?.form_id} value={form?.form_id}>
                {form?.form_contents?.formTitle}
              </option>
            );
          })}
        </select>
      </div>

      <div className="event-input-container event-primary-details-container">
        <div>
          <div className="event-label">Event Name</div>
          <input
            value={eventDetails?.title}
            onChange={handleEventDetailChange}
            name="title"
            className="event-input"
            placeholder="Event Name"
            type="text"
          />
        </div>
        {/* <div>
          <div className="event-label">Location Type</div>
    
          <select
            name="location"
            value={eventDetails?.location}
            onChange={handleEventDetailChange}
            className="event-input"
            placeholder="Location Type"
            style={{ width: "100%" }}
          >
            <option value="in-person">In-person</option>
            <option value="virtual">Virtual</option>
          </select>
        </div> */}
        {/* <div>
          <div className="event-label">City</div>
          <input
            value={eventDetails?.city}
            onChange={handleEventDetailChange}
            name="city"
            className="event-input"
            placeholder="City"
            type="text"
          />
        </div> */}
        {/* <div>
          <div className="event-label">State</div>
          <select
            name="state"
            value={eventDetails?.state}
            onChange={handleEventDetailChange}
            className="event-input"
            placeholder="State"
            style={{ width: "100%" }}
          >
            {STATES.map(item => <option value={item.abbreviation}>{item.name}</option>)}
          </select>
        </div> */}
      </div>

      <div className="event-input-container event-date-container">
        <div className="date-filter-container">
          <div>
            <div className="event-label">
              <span>
                <span style={{ color: 'red' }}>*</span>{` `}
                {eventDetails?.event_type === "single_day"
                  ? "Date"
                  : "Start Date"}
              </span>
            </div>
            <DatePicker
              className="event-input event-date-picker"
              placeholderText="From"
              customInput={<DateCustomInput label="From" />}
              // value={format(new Date(eventDetails?.start), "MMM dd, yyy")}
              value={
                eventDetails?.start &&
                moment(eventDetails.start)
                  .utcOffset(eventDetails.start)
                  .format("MMM DD, yy")
              }
              onChange={(value) => {
                // handleEventDetailChange({
                //   ...eventDetails,
                //   attendance_start_date: value,
                // });

                // handleEventDetailChange({
                //   target: {
                //     name: 'attendance_start_date',
                //     value
                //   }
                // });

                setCurrentEvent({
                  ...eventDetails,
                  start: value.toLocaleDateString(),
                });
              }}
            />
          </div>

          {["multi_day", "recurring"].includes(eventDetails?.event_type) && (
            <div>
              <div className="event-label">End Date</div>
              <DatePicker
                className="event-input event-date-picker"
                placeholderText="From"
                customInput={<DateCustomInput label="To" />}
                selected={eventDetails?.attendance_end_date}
                onChange={(value) => {
                  setCurrentEvent({
                    ...eventDetails,
                    end: value,
                  });
                }}
              />
            </div>
          )}
          {eventDetails?.event_type === "recurring" && (
            <>
              <div>
                <div className="event-label">Repeat Type</div>
                <select
                  onChange={handleEventDetailChange}
                  name="state"
                  className="event-input event-select"
                  placeholder="State"
                >
                  <option value="weekly">Weekly</option>
                </select>
              </div>
              <div>
                <div className="event-label">Repeat On</div>
                <select
                  onChange={handleEventDetailChange}
                  name="repeat_on"
                  className="event-input event-select"
                  placeholder="Repeat On"
                >
                  {OPTION_DAYS.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              </div>
            </>
          )}

          <div>
            <div className="event-label">Start Time</div>
            <DatePicker
              className="event-input event-time-picker"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              disabled={false}
              placeholder="Start Time"
              customInput={
                <TimeCustomInput
                  label="start_time"
                  name="attendance_start_time"
                />
              }
              // onChange={handleTimeChange("attendance_start_time")}
              onChange={(value) => {
                handleEventDetailChange({
                  target: {
                    name: "attendance_start_time",
                    value,
                  },
                });
              }}
              value={eventDetails?.attendance_start_time}
            />
          </div>
          <div>
            <div className="event-label">End Time</div>
            <DatePicker
              className="event-input event-time-picker"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              disabled={false}
              customInput={
                <TimeCustomInput label="end_time" name="attendance_end_time" />
              }
              // onChange={handleTimeChange("attendance_end_time")}
              onChange={(value) => {
                handleEventDetailChange({
                  target: {
                    name: "attendance_end_time",
                    value,
                  },
                });
              }}
              value={eventDetails?.attendance_end_time}
            />
          </div>

          {eventDetails?.event_type === "single_day" && (
            <div className="event-checkbox-container">
              <input type="checkbox" />
              All Day
            </div>
          )}
          {eventDetails?.event_type === "multi_day" && (
            <div className="event-checkbox-container">
              <input type="checkbox" />
              Time Varies Daily
            </div>
          )}

          {eventDetails?.event_type === "recurring" && (
            <div className="event-checkbox-container">
              <input type="checkbox" />
              Event Ends
            </div>
          )}
        </div>
      </div>

      {/* <div className="event-input-container event-date-container">
        <div>
          <div className="event-label">Registration Deadline</div>
          <DatePicker
            className="event-input event-date-picker"
            placeholderText="Registration Deadline"
            customInput={<DateCustomInput />}
            selected={eventDetails?.registration_deadline}
            onChange={(value) => {
              setCurrentEvent({
                ...eventDetails,
                registration_deadline: value,
              });
            }}
          />
        </div>
        <div className="event-checkbox-container">
          <input type="checkbox" />
          <span>No Deadline</span>
        </div>
      </div> */}

      <div className="event-input-container">
        <div>
          <div className="event-label">Description</div>
          <textarea
            rows={5}
            // value={eventDetails?.description}
            className="event-input event-input-textarea"
            onChange={handleEventDetailChange}
            name="description"
          // placeholder={
          //   eventDetails?.description
          //     ? eventDetails?.description
          //     : "Description"
          // }
          >
            {eventDetails?.description}
          </textarea>
        </div>
      </div>
      <div>
        <div>
          <div className="event-label">Hours to Calculate</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <input
              checked={eventDetails?.calc_hour_type?.toString() === '0'}
              type="radio"
              name="calc_hour_type"
              value="0"
              onChange={handleEventDetailChange}
              style={{ position: 'relative', top: 3, left: 3 }}
            />{` `}
            <span className="event-label">Mentoring</span>
          </div>
          <div>
            <input
              checked={eventDetails?.calc_hour_type?.toString() === '1'}
              type="radio"
              name="calc_hour_type"
              value="1"
              onChange={handleEventDetailChange}
              style={{ position: 'relative', top: 3, left: 3 }}
            />{` `}
            <span className="event-label">Volunteer</span>
          </div>
        </div>
      </div>


      <div className="event-input-container event-group-container">
        {/* <div>
          <div className="event-label">Admin</div>
          <select className="event-input" placeholder="Event Name">
            <option value="">Please Select Admin</option>
            {Array.isArray(adminVendor) &&
              adminVendor?.map((vendor) => {
                return (
                  <option key={vendor?.id} value={vendor?.id}>
                    {vendor?.name}
                  </option>
                );
              })}
          </select>
        </div> */}

        {/* <div>
          <div className="event-label">Groups</div>
          <select
            value={selectedGroup}
            onChange={handleSelectGroupChange}
            className="event-input"
            placeholder="Form"
          >
            <option value="">Please Select Group</option>
            {groups.map((group) => {
              return (
                <option key={group?.app_grp_id} value={group?.app_grp_id}>
                  {group?.name}
                </option>
              );
            })}
          </select>
        </div> */}
      </div>
    </EventDetailsContainer>
  );
};

export default EventDetailsInput;
