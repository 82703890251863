import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { parse } from "query-string";
import moment from "moment";
import { format, isWithinInterval } from "date-fns";
import uniqBy from "lodash.uniqby";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleLeft, faBook, faCalendarAlt, faMapMarkerAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import {
  requestAttendanceByEvent,
  requestUpdateAttendance,
} from "../../../../redux/actions/Attendance";
import { requestGetForms } from "../../../../redux/actions/FormBuilder";
import {
  requestVendor
} from "../../../../redux/actions/Vendors";

// import ProfileImg from '../../../../images/defaultprofile.png';

import ImagePreviewModal from "../modal/imagePreview";

// import { getNameFromCustomForm } from '../../Grades/utils'

import { s3BucketRootPath } from "../../../../constants/aws";

import Confirmation from "../../../../helpers/Confirmation";
import { addTimeHour, timeCompare } from "../../../../helpers/Date";

// import { militaryToRegularTime } from '../../../../helpers/Date';
// import DisplayEventModal from '../../BCDisplayCalendar/ActivityDisplayModal';

import EventAttendeeList from "../events/event_attendee_list";
import EventSummaryList from "./event_summary_list";
import EventDetailsInput from "./event_details_input";

// ICONS
// import EditIcon from "../../../../constants/icons/edit.svg";
import CalendarIcon from "../../../../constants/icons/calendar-light.svg";
// import LocationIcon from "../../../../constants/icons/location.svg";
import NotebookIcon from "../../../../constants/icons/notebook.svg";
import StudentIcon from "../../../../constants/icons/users.svg";
import LeftArrow from "../../../../constants/icons/left-arrow.svg";

const EventAttendeeStyled = styled.div`

    padding: 40px;

    .circle-icon {
		border-radius: 50%;
		width: 15px;
		height: 15px;
		margin: 0 auto;
	}

    .field {
		// padding: 5px !important;
		// margin: 5px !important;
	}
	.field-input:placeholder-shown + .field-label {
		max-width: calc(100% - 30%) !important;
	}

	.field-label,
	.field-input {
		transition: all 0.2s;
		touch-action: manipulation;
	}

	.field-input {
		font-size: 18px;
		border: 0;
		border-bottom: 2px solid #ccc;
		font-family: inherit;
		-webkit-appearance: none;
		-moz-appearance: none;
		border-radius: 0;
		padding: 5px;
		cursor: text;
		line-height: 1.8;

		padding: 5px 0;
		width: 100%;
		display: block;
		text-indent: 5px;
	}
	.form-group .form-control {
		font-size: 18px;
		border: 0;
		border-bottom: 2px solid #ccc;
		-webkit-appearance: none;
		-moz-appearance: none;
		border-radius: 0;
		padding: 10px;
	}

	.field {
		display: flex;
		flex-flow: column-reverse;
		margin-bottom: 1em;
	}

	.field-label,
	.field-input {
		transition: all 0.2s;
		touch-action: manipulation;
	}

	.field-input {
		font-size: 18px;
		border: 0;
		border-bottom: 2px solid #ccc;
		font-family: inherit;
		-webkit-appearance: none;
		-moz-appearance: none;
		border-radius: 0;
		padding: 5px;
		cursor: text;
		line-height: 1.8;

		padding: 5px 0;
		width: 100%;
		display: block;
		text-indent: 5px;
		margin-top: 8px;
		margin-bottom: -5px;
	}

	.field-label {
		font-size: 14px;
		color: #4b525a;
	}

	.field-input:placeholder-shown + .field-label {
		overflow: hidden;
		transform-origin: left bottom;
		transform: translate(0, 2.125rem) scale(1.4);
	}

	.field-input::placeholder {
		opacity: 0;
		transition: inherit;
		font-size: 12px;
	}

	.field-input:focus::placeholder {
		opacity: 1;
	}

	.field-input:focus + .field-label {
		transform: translate(0, 0) scale(1);
		cursor: pointer;
		// margin-bottom: 5px;
		font-weight: bold;
	}


    .gridView .block .extra_activitybox {
		text-align: center;
	}

	.gridView {
		margin-top: 3rem;
        padding: 12px !important;
		display: grid;
		grid-gap: 18px;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        background-color: white;
	}

	.gridView .block {
		width: auto;
		border: 1px solid #ccc;
		box-shadow: 0 0 10px #ccc;
		border-bottom: 4px solid #f5812f;
	}

	.gridView .block .extra_activitybox .img-container {
		padding: 1rem;
	}
	.gridView .block .extra_activitybox .img-container img {
		border-radius: 50%;
		height: 100px;
		width: 100px;
		box-shadow: 0 0 5px #716464;
	}
	.gridView .block .extra_activitybox .attendance-name {
		padding-bottom: 1rem;
	}
	.gridView .block .extra_activitybox .attendance-name span {
		color: #000;
		font-weight: 600;
		text-decoration: none;
		transition: 0.15s ease-in-out;
	}

	.gridView .block .extra_activitybox .attendance-action {
		display: flex;
		justify-content: space-around;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}
	.gridView .block .extra_activitybox .attendance-action > div {
		flex: 1;
		padding: 1rem 0;
	}
	.gridView .block .extra_activitybox .attendance-action > div:not(:last-child) {
		border-right: 1px solid #ddd;
	}
	.gridView .block .extra_activitybox .attendance-hours {
		display: flex;
		padding: 1rem;
		border-bottom: 1px solid #ddd;
	}
	.gridView .block .extra_activitybox .attendance-hours .field {
		padding: 0 0.5rem;
	}
	.gridView .block .extra_activitybox .attendance-hours .field > input {
		text-align: center;
	}
	.gridView .block .extra_activitybox .attendance-hours .field > label {
		font-size: 12px;
	}

	.gridView .block .extra_activitybox .attendance-invitation {
		padding: 1rem;
		color: gray;
		font-size: 14px;
	}
	.gridView .block .extra_activitybox .attendance-invitation span {
		color: #000;
		font-weight: 600;
	}

    .actionBtn {
		margin-top: 1.5rem !important;
	}
	.actionBtn button {
		border: none;
		color: #f5812f;
		font-size: 18px;
		border-radius: 3px;
		background: rgb(245 129 47 / 25%);
		transition: 0.15s ease-in-out;
	}
	.actionBtn button:hover {
		color: #fff;
		background: #e87828;
	}


   
     .timeOutBtn {
        color: ${({ theme }) => theme.button.textColor.primary};
        font-size: ${({ theme }) => theme.button.fontSize} !important;
        border: none;
        box-shadow: 0px 3px 6px #908e8e;
        padding-top: 1em;
        padding-bottom: 1em;
        border-radius: 10px !important;

        padding: 10px;
        display: block;
        margin: 1em auto;
        background-color: red !important;
        border: none;
        width: 100% !important;
    }


    .event-details-container {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center
        text-align: center;
        background-color: white;
        padding: 24px
    }

    .event-info-container {
        display: flex;
        flex-direction: column;
        padding-left: 12px;
    }

    .event-tag-container {
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .event-tag-container > span:not(:first-child) {
        margin-left: 12px;
    }


    .event-type-tag.event-tag-primary {

        background-color: #E7F5FF !important;
        border: 1px solid #1478C4;
        color: #1478C4 !important;
    }
    .event-type-tag {
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 50px;
        background-color: #F4F4F5;
        border: 1px solid #CACACB;
    }
    .btn-edit-icon {
        cursor: pointer;
        width: 22px; 
        height: 22px;
        margin-top: 5px;
    }

    .event-input {
        padding: 8px 16px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    .event-input-textarea {
        resize: none;
        width: 97%;
    }

    .event-input-container {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    
	.btn-save {
		background-color: #FC9A3B;
		border: none;
		border-radius: 35px;
		font-weight: bold;
		margin-right: 12px;
		width: 140px;
		margin-top: 8;
		margin-bottom: 8px
	}

  .btn-cancel {
		background-color: #FC9A3B;
		border: none;
		border-radius: 35px;
		font-weight: bold;
		margin-right: 12px;
		width: 100px;
		margin-top: 8;
		margin-bottom: 8px
	}
    
    .detail-title {
        font-weight: bold;
    }
`;

// function getCurrentTime() {
//     const currentDate = new Date();
//     const currentTime = format(currentDate, "HH:mm");
//     return currentTime;
// }

const getEventById = async (eventId) => {
  const response = await fetch(`${process.env.API_HOST}/api/event/${eventId}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  return response.json();
};

const LOCATION_OPTIONS = [
  { label: "In-Person", value: "in-person" },
  { label: "Virtual", value: "virtual" },
];

const EventAttendee = (props) => {
  const dispatch = useDispatch();

  const { auth, attendance, groups, form, vendors } = useSelector((state) => {
    return {
      auth: state.auth,
      attendance: state.attendance,
      groups: state.groups,
      form: state.form,
      vendors: state.vendors,
    };
  });

  const { register, errors, clearError, setError, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const location = useLocation();
  const { event_id } = useParams();
  const queryParams = parse(location.search);

  const [appGroups, setAppGroups] = useState([]);
  const [currentAttendance, setCurrentAttendance] = useState([]);
  const [uniqueID, setUniqueID] = useState("");
  const [isUniqueIDVisible, setIsUniqueIDVisible] = useState(true);
  // const [defaultAttendance, setDefaultAttendance] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({
    calc_hour_type: '0'
  });
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [markedAsAbsent, setMarkedAsAbsent] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [isImagePreviewModalVisible, setIsImagePreviewModalVisible] =
    useState(false);
  const [currentQrCodeUrl, setCurrentQrCodeUrl] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);
  const [originalAttendance, setOriginalAttendance] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    from: null,
    to: null,
  });

  const [groupsSummary, setGroupsSummary] = useState([]);
  const [defaultVendor, setDefaultVendor] = useState(null);

  const currentLocation = LOCATION_OPTIONS.find(
    (item) => item.value === currentEvent?.location
  );

  useEffect(() => {
    if (vendors && vendors.length > 0) {
      const currentVendor = vendors?.find((vendor) => vendor.is_default);
      setDefaultVendor(currentVendor);
    }
  }, [vendors]);


  useEffect(() => {
    if (queryParams?.edit) {
      setIsEditMode(true);
    }
  }, [])

  useEffect(() => {
    if (auth && !Object.keys(vendors).length) {
      dispatch(requestVendor(auth.user_id));
    }
  }, [auth]);

  // useEffect(() => {
  //   console.log('defaultVendor',defaultVendor)
  //   if (defaultVendor) {
  //     requestGetForms({
  //       vendor: defaultVendor.id,
  //       currentUser: auth.user_id,
  //       isOwner: !!(auth.user_id == defaultVendor?.user),
  //       categories: [],
  //     });
  //   }
  // }, [defaultVendor]);

  useEffect(() => {
    if (attendance.isAttendanceUpdateSuccess) {
      const redirect = () => {
        setTimeout(() => {
          if (queryParams?.vendorId) {
            const currentVendorId = parseInt(queryParams?.vendorId);
            window.location.replace(
              `/dashboard/attendance/newevents?vendorId=${currentVendorId}&attendanceType=mentoring&name=all`
            );
          }
        }, 2000);
      };
      redirect();
    }
  }, [attendance.isAttendanceUpdateSuccess]);

  const handleSelectAllEvents = (e) => {
    const { checked } = e.target;

    let updatedDefaultList = [...originalAttendance];
    let updatedList = [...currentAttendance];
    updatedList = updatedList.map((item) => {
      return {
        ...item,
        selected: checked,
      };
    });

    updatedDefaultList = updatedDefaultList.map((item) => {
      return {
        ...item,
        selected: checked,
      };
    });
    setCurrentAttendance([...(updatedList || [])]);
    setOriginalAttendance([...(updatedDefaultList || [])]);
  };

  const handleSelectEvent = (item) => (e) => {
    const { checked } = e.target;
    const listState = {
      ...item,
      selected: checked,
    };

    let updatedList = [...currentAttendance];
    const listIndex = updatedList.findIndex(
      (item) => item.child_id === listState.child_id
    );

    updatedList[listIndex] = {
      ...listState,
    };
    setCurrentAttendance([...(updatedList || [])]);
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
    if (keyword === "") {
      setCurrentAttendance([...originalAttendance]); // Show all items when search keyword is cleared
    } else {
      filterResults(keyword); // Perform the search when keyword changes
    }
  };

  const handleSearchSubmit = (e) => {
    console.log("e charcode", e);
    if (e.key === "Enter" || e.charCode === 13) {
      filterResults();
    }
  };

  const filterResults = (keyword = searchKeyword) => {
    if (keyword) {
      const formattedKeyword = keyword.toLowerCase();
      const searchEvents = originalAttendance.filter(
        (item) =>
          item?.firstname?.toLowerCase().includes(formattedKeyword) ||
          item?.lastname?.toLowerCase().includes(formattedKeyword)
      );
      setCurrentAttendance(searchEvents);
    } else {
      setCurrentAttendance([...originalAttendance]); // Ensure originalAttendance holds all initial data
    }
  };

  const handleDateFilterChange = (name) => (value) => {
    const formattedValue = value ? format(new Date(value), "yyyy-MM-dd") : null;
    const currentState = {
      ...selectedDate,
      [name]: formattedValue,
    };

    const fromDate = currentState.from ? new Date(currentState.from) : null;
    const toDate = currentState.to ? new Date(currentState.to) : fromDate;

    const filteredEvents = originalAttendance?.filter((item) => {
      const startDate = item?.attendance_start_time
        ? new Date(item.attendance_start_time)
        : null;
      const endDate = item?.attendance_end_time
        ? new Date(item.attendance_end_time)
        : null;

      // Check if startDate or endDate is within the interval
      const isStartDateWithin =
        startDate &&
        isWithinInterval(startDate, {
          start: fromDate,
          end: toDate,
        });

      const isEndDateWithin =
        endDate &&
        isWithinInterval(endDate, {
          start: fromDate,
          end: toDate,
        });

      return isStartDateWithin || isEndDateWithin;
    });

    setCurrentAttendance(filteredEvents);

    setSelectedDate(currentState);
  };

  const handleFilterByGroup = (e) => {
    if (e.target.value) {
      const filteredEvents = currentAttendance?.filter((item) =>
        item?.app_group_id?.includes(e.target.value)
      );
      setCurrentAttendance(filteredEvents);
    } else {
      setCurrentAttendance([...originalAttendance]);
    }
  };

  useEffect(() => {
    if (groups.application_groups) {
      let appGroupList = uniqBy(groups.application_groups, "pool_id");
      setAppGroups(appGroupList);
    }
  }, [groups.application_groups]);

  useEffect(() => {
    if (queryParams?.vendorId) {
      getCurrentEvent(event_id);
      dispatch(
        requestAttendanceByEvent({
          eventId: event_id,
          attendanceType: queryParams?.type === 'forms' ? 'forms' : 'mentoring',
          appGroupId: queryParams?.appGroupId || null,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      attendance?.currentEventAttendance?.attendance?.length > 0 &&
      appGroups.length > 0
    ) {
      let summary = attendance?.currentEventAttendance?.attendance.reduce(
        (accum, item) => {
          const group = appGroups.find(
            (item2) =>
              item2.app_grp_id === item.app_group_id ||
              item2.form === item.app_group_id
          );
          if (accum[item.app_group_id]) {
            return {
              ...accum,
              [item.app_group_id]: {
                name: group?.name || "--",
                app_group_id: item.app_group_id,
                present:
                  item.attendance_status === "Present"
                    ? accum[item.app_group_id]?.present + 1
                    : accum[item.app_group_id]?.present,
                total_student: accum[item.app_group_id]?.total_student + 1,
              },
            };
          } else {
            return {
              ...accum,
              [item.app_group_id]: {
                name: group?.name || "--",
                app_group_id: item.app_group_id,
                present: item.attendance_status === "Present" ? 1 : 0,
                total_student: 1,
              },
            };
          }
        },
        {}
      );

      if (summary) {
        summary = Object.keys(summary).map((key) => summary[key]);
        setGroupsSummary(summary);
      }
    } else {
      setGroupsSummary([]);
    }
  }, [attendance, appGroups]);

  useEffect(() => {
    if (attendance.currentEventAttendance.attendance) {
      setCurrentAttendance(attendance.currentEventAttendance.attendance);
      setOriginalAttendance(attendance.currentEventAttendance.attendance);
    }
  }, [attendance.currentEventAttendance.attendance]);

  const getCurrentEvent = async (id) => {
    try {
      const response = await getEventById(id);

      setCurrentEvent({
        ...currentEvent,
        ...response?.event,
        city: response?.event?.City,
        state: response?.event?.State,
        attendance_start_time:
          response?.event?.start &&
          moment(response?.event?.start)
            .utcOffset(response?.event?.start)
            .format("hh:mm a"),
        attendance_end_time:
          response?.event?.end &&
          moment(response?.event?.end)
            .utcOffset(response?.event?.end)
            .format("hh:mm a"),
      });
    } catch (e) {
      console.log("getCurrentEvent error", e);
    }
  };

  const handleSearchUniqueID = (e) => {
    setUniqueID(e.target.value);
  };

  const handleSeachUnique = (e) => {
    if (e.charCode == 13) {
      let results = [];
      if (uniqueID) {
        const searchUniqueID = currentAttendance?.filter((item) =>
          item?.child_id?.includes(uniqueID)
        );
        results = [...(searchUniqueID || [])];
      } else {
        results = [...(currentAttendance || [])];
      }
      setCurrentAttendance([...(results || [])]);
    }
  };

  const handleAttendance = (payload, attendanceType = "") => {
    let currentIndex = currentAttendance.findIndex(
      (app) => app.child_id === payload.child_id
    );
    let updatedAttendance = [...currentAttendance];
    if (currentIndex > -1) {
      updatedAttendance[currentIndex] = {
        ...updatedAttendance[currentIndex],
        excused: null,
        attendance_status: attendanceType,
      };
    }
    setCurrentAttendance([...(updatedAttendance || [])]);
  };

  const handleHours = (payload, hours, type = "volunteer_hours") => {
    let updatedAttendance = [...(currentAttendance || [])];
    let currentIndex = updatedAttendance.findIndex(
      (app) => app.child_id === payload.child_id
    );

    if (currentIndex > -1) {
      updatedAttendance[currentIndex] = {
        ...updatedAttendance[currentIndex],
        [type]: hours,
      };
      setCurrentAttendance(updatedAttendance);
    }
  };

  const handleCheckOut = (payload) => {
    let updatedAttendance = [...(currentAttendance || [])];
    let currentIndex = updatedAttendance.findIndex(
      (app) => app.child_id === payload.child_id
    );

    if (currentIndex > -1) {
      updatedAttendance[currentIndex] = {
        ...updatedAttendance[currentIndex],
        attendance_end_time: getCurrentTime(),
      };
      setCurrentAttendance(updatedAttendance);
    }
  };

  // const handleCheckOutAll = () => {

  //     let updatedAttendance = [...(currentAttendance || [])];

  //     updatedAttendance = updatedAttendance.map(att => {
  //         if (!att.attendance_end_time) {
  //             return {
  //                 ...att,
  //                 attendance_end_time: getCurrentTime(),
  //             }
  //         }

  //         return {
  //             ...att,

  //         }
  //     });

  //     setCurrentAttendance(updatedAttendance);

  //     setIsConfirmationVisible(true);
  // }

  const handleSubmit = () => {
    setIsConfirmationVisible(true);
  };

  console.log('currentAttendance', currentAttendance)

  console.log('currentEvent', currentEvent)

  const handleUpdateAttendance = () => {
    const payload = {
      attendance_list: currentAttendance.map((att) => {
        return {
          attendance_id: parseInt(att.attendance_id),
          app_group_id: att.app_group_id,
          attendance_start_time:
            att.attendance_start_time || currentEvent.attendance_start_time,
          attendance_end_time:
            att.attendance_end_time || currentEvent.attendance_end_time,
          attendance_status: !att.attendance_status
            ? markedAsAbsent
              ? "Absent"
              : ""
            : att.attendance_status,
          mentoring_hours: parseInt(att.mentoring_hours),
          volunteer_hours: parseInt(att.volunteer_hours),
          child_id: att.child_id,
        };
      }),
      app_group_id: currentAttendance[0]?.app_group_id,
      attendance_type: queryParams?.type === 'forms' ? 'forms' : 'mentoring',
      attendance_date: `${format(new Date(currentEvent?.start), "yyyy-MM-dd")}`,
      attendance_start_time: currentEvent.attendance_start_time,
      attendance_end_time: currentEvent.attendance_end_time,
      event_name: currentEvent?.title,
      location: currentEvent?.location,
      description: currentEvent?.description,
      calc_hour_type: parseInt(currentEvent?.calc_hour_type),
      vendorId2: parseInt(queryParams?.vendorId || 0),
      create_event: false,
      event_id,
      city: currentEvent?.city,
      state: "",
      // app_group_id: currentAttendance[0]?.app_group_id,
      // attendance_type: currentAttendance[0]?.attendance_type,
      // attendance_date: `${format(
      //   new Date(parseInt(currentAttendance[0]?.attendance_date)),
      //   "yyyy-MM-dd"
      // )} 00:00:00`,
      // event_name: currentAttendance[0].event_name,
      // location: currentAttendance[0].location,
      // description: currentAttendance[0].description,
      // vendorId2: parseInt(queryParams?.vendorId || 0),
      // create_event: false,
      // event_id,
    };


    dispatch(requestUpdateAttendance(payload));
    setTimeout(() => {
      dispatch(
        requestAttendanceByEvent({
          eventId: event_id,
          attendanceType: queryParams?.type,
        })
      );
    }, 1500);
    setIsConfirmationVisible(false);
  };

  const handleEventDetailChange = (e) => {
    const { name, value } = e.target;

    let payload = {
      ...(currentEvent || {}),
      [name]: value,
    };
    if (name === "attendance_start_time") {
      const time = format(new Date(value), "hh:mm a");
      console.log("valueee", value);
      payload = {
        ...(currentEvent || {}),
        attendance_start_time: time,
        attendance_end_time: addTimeHour(time, 1),
      };
    } else if (name === "attendance_end_time") {
      const time = format(new Date(value), "h:mm a");
      const timeCompareValue = timeCompare(
        time,
        currentEvent.attendance_start_time
      );
      if (timeCompareValue > -1) {
        payload = {
          ...(currentEvent || {}),
          attendance_end_time: time,
        };
      } else {
        payload = {
          ...(currentEvent || {}),
          attendance_end_time: addTimeHour(currentEvent.attendance_start_time, 1),
        };
      }
    }

    else if (name === "calc_hour_type") {
      payload = {
        ...(currentEvent || {}),
        calc_hour_type: value

      };

      console.log('payload', payload)

      swapHours(value);
    }
    setCurrentEvent({
      ...payload,
    });
  };

  const handleSetAttendance = (attendances = []) => {
    setCurrentAttendance(attendances);
  };

  const swapHours = (type) => {

    let updatedAttendance = [...(originalAttendance || [])];
    let updatedFilteredAttendance = [...(currentAttendance || [])];

    let targetHoursField = type === '1' ? 'volunteer_hours' : 'mentoring_hours';
    let prevFields = type === '0' ? 'volunteer_hours' : 'mentoring_hours';



    updatedAttendance = updatedAttendance.map(item => {
      let updatedItem = {
        [targetHoursField]: item[prevFields],
        [prevFields]: 0
      }
      return {
        ...item,
        ...updatedItem

      }
    });

    updatedFilteredAttendance = updatedFilteredAttendance.map(item => {
      let updatedItem = {
        [targetHoursField]: item[prevFields],
        [prevFields]: 0
      }
      return {
        ...item,
        ...updatedItem

      }
    });

    setOriginalAttendance([...(updatedAttendance || [])]);
    setCurrentAttendance([...(updatedFilteredAttendance || [])]);
  }

  return (
    <EventAttendeeStyled>
      <div>
        <div style={{ marginBottom: 12 }}>
          <div style={{ color: "#6C6C6C", fontSize: 16, fontWeight: 700 }}>
            100 Black Men
          </div>
          <div style={{ fontWeight: 600, fontSize: 32 }}>Attendance</div>
        </div>

        {currentEvent ? (
          <div className="event-details-container">
            <div
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* {isEditMode ? (
                  <div
                    style={{
                      textDecoration: "none",
                      color: "black",
                      paddingTop: 7,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsEditMode((prevState) => !prevState);
                    }}
                  >
                    <img src={LeftArrow} />
                    {` `}
                  </div>
                ) : (
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      paddingTop: 5,
                    }}
                    to={`/dashboard/attendance/newevents?vendorId=${queryParams?.vendorId}`}
                  >
                    <img src={LeftArrow} />
                    {` `}
                  </Link>
                )} */}

                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    paddingTop: 5,
                  }}
                  to={`/dashboard/attendance/newevents?vendorId=${queryParams?.vendorId}`}
                >
                  <img src={LeftArrow} />
                  {` `}
                </Link>
                {currentEvent && (
                  <div style={{ fontSize: 24, marginLeft: 12 }}>
                    <b>{isEditMode ? "Edit Event" : currentEvent?.title}</b>
                    {`  `}

                    {/* <img
                      onClick={() => {
                        setIsEditMode((prevState) => !prevState);
                      }}
                      src={EditIcon}
                      alt="edit"
                      className="btn-edit-icon"
                    /> */}
                  </div>
                )}
              </div>
              <div style={{ paddingTop: 5 }}>
                {isEditMode ? (
                  <>
                    <button
                      className="btn-save"
                      style={{ width: "100px" }}
                      onClick={() => {
                        setIsConfirmationVisible(true);
                      }}
                      type="button"
                    >
                      {attendance && attendance.isAttendanceUpdateLoading
                        ? "Please Wait..."
                        : "Save"}
                    </button>
                    <button

                      className="btn-cancel"
                      type="button"
                    >
                      <Link
                        style={{
                          textDecoration: 'none'
                        }}
                        to={`/dashboard/attendance/newevents?vendorId=${queryParams?.vendorId}`}
                      >
                        <span style={{ color: 'black' }}>Cancel</span>
                        {` `}
                      </Link>
                    </button>
                  </>

                ) : (
                  currentEvent?.user && (
                    <>
                      <img src={StudentIcon} />
                      <span
                        style={{
                          position: "relative",
                          top: -12,
                          marginLeft: 12,
                        }}
                      >
                        {`${currentEvent?.user?.first_name} ${currentEvent?.user?.last_name}`}
                      </span>
                    </>
                  )
                )}
              </div>
            </div>

            <div>
              {isEditMode ? (
                <EventDetailsInput
                  eventDetails={currentEvent}
                  forms={defaultVendor?.forms || []}
                  groups={groups?.application_groups}
                  handleEventDetailChange={handleEventDetailChange}
                  handleTimeChange={() => { }}
                  handleSelectFormChange={() => { }}
                  handleSelectGroupChange={() => { }}
                  selectedForm={queryParams?.type === 'forms' ? currentEvent?.attendance_app_group : 'mentoring'}
                  selectedGroup={null}
                  setCurrentEvent={setCurrentEvent}
                  register={register}
                  vendors={vendors}
                />
              ) : (
                <>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "2px solid #F4F4F5",
                    }}
                  >
                    <div>
                      <img
                        src={`${s3BucketRootPath}/${currentEvent.qr_code_url}`}
                        style={{ width: 150, height: 150 }}
                      />
                    </div>
                    <div className="event-info-container">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: 14,
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              width: 300,
                              display: "flex",
                              flexDirection: "row",
                              borderRight: "2px solid #F4F4F5",
                            }}
                          >
                            <div>
                              <img src={CalendarIcon} />
                            </div>
                            <div style={{ paddingLeft: 12 }}>
                              <div className="detail-title">
                                {/* {format(new Date(currentEvent?.start), "PPPP")} */}
                                {currentEvent?.start &&
                                  moment(currentEvent.start)
                                    .utcOffset(currentEvent.start)
                                    .format("MMMM Do YYYY")}
                              </div>
                              <div style={{ color: "#6C6C6C" }}>
                                {/* {format(
                                  new Date(currentEvent?.start),
                                  "hh:mm a"
                                )} */}
                                {currentEvent?.start &&
                                  moment(currentEvent.start)
                                    .utcOffset(currentEvent.start)
                                    .format("hh:mm a")}{" "}
                                {/* {format(new Date(currentEvent?.end), "hh:mm a")} */}
                                {currentEvent?.end &&
                                  moment(currentEvent.end)
                                    .utcOffset(currentEvent.end)
                                    .format("hh:mm a")}
                              </div>
                            </div>
                          </div>
                          {/* Location */}
                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: 250,
                              paddingLeft: 12,
                            }}
                          >
                            <div>
                              <img src={LocationIcon} />
                            </div>
                            <div style={{ paddingLeft: 12 }}>
                              <div className="detail-title">
                                {currentLocation?.label
                                  ? currentLocation?.label
                                  : "No Location Type"}
                              </div>
                              {currentEvent?.city ? (
                                <div style={{ color: "#6C6C6C" }}>
                                  {currentEvent?.city}
                                </div>
                              ) : (
                                <div style={{ color: "#6C6C6C" }}>
                                  No Location
                                </div>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div
                        style={{
                          paddingTop: 14,
                          paddingBottom: 14,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <img src={NotebookIcon} />
                        </div>
                        <div style={{ paddingLeft: 12 }}>
                          <div className="detail-title">Description</div>
                          <div style={{ fontSize: 18 }}>
                            {currentEvent?.description
                              ? currentEvent.description
                              : "No Description"}
                            {/* Discover the future of tech at Chi-Tech Conference!
                            Join experts and visionaries as we explore AL,
                            cybersecurity, biotech, and more. Whether you're a
                            pro or just curious, come spark ideas and shape the
                            future with us! */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="event-tag-container">
                    <span className="event-type-tag event-tag-primary ">
                      Upcoming
                    </span>
                    <span className="event-type-tag">Multi-Day Event</span>
                  </div> */}
                </>
              )}
            </div>
          </div>
        ) : (
          <span />
        )}
      </div>

      <br />
      <br />
      <div className="attendee">


        <div>
          {isEditMode ? (
            <div>
              <EventAttendeeList
                event={currentEvent}
                data={currentAttendance}
                handleAttendance={handleAttendance}
                handleHours={handleHours}
                handleSetAttendance={handleSetAttendance}
                handleSave={() => {
                  setIsConfirmationVisible(true);
                }}
                setIsEditMode={setIsEditMode}
                vendorId={queryParams?.vendorId}
              />

            </div>
          ) : (
            <div>
              <EventSummaryList
                event={currentEvent}
                appGroups={appGroups}
                selectedDate={selectedDate}
                searchKeyword={searchKeyword}
                data={currentAttendance}
                groupsSummary={groupsSummary}
                handleAttendance={handleAttendance}
                handleHours={handleHours}
                uniqueID={uniqueID}
                handleSearchUniqueID={handleSearchUniqueID}
                handleSeachUnique={handleSeachUnique}
                handleSearch={handleSearch}
                handleSearchSubmit={handleSearchSubmit}
                handleDateFilterChange={handleDateFilterChange}
                handleFilterByGroup={handleFilterByGroup}
                handleSelectEvent={handleSelectEvent}
                handleSelectAllEvents={handleSelectAllEvents}
                isUniqueIDVisible={isUniqueIDVisible}
                setIsUniqueIDVisible={setIsUniqueIDVisible}
                setIsEditMode={setIsEditMode}
                isEditMode={isEditMode}
              />

            </div>
          )}

        </div>
        {/* <div className="gridView">
                {currentAttendance.map(app => {
                    const currentChild = app.form_contents ? getNameFromCustomForm(app.form_contents) : {
                        lastname: app?.lastname,
                        firstname: app?.firstname
                    }
                    let profile = app?.child?.image || ''
                    if (app.form_contents) {
                        const { formData = [] } = typeof app.form_contents === 'string' ? JSON.parse(app.form_contents) : app.form_contents
                        const { fields = [] } = formData.find(e => e.fields[0].tag === 'profileImage') || {}
                        if (fields.length) {
                            const { value } = fields[0]
                            const { url } = value ? JSON.parse(value) : {}
                            profile = url?.includes('file/') ? 'https://bcombs.s3.amazonaws.com/' + url : url;
                        }
                    } else if (!app.form_contents && profile) {
                        profile = profile?.includes('file/') ? 'https://bcombs.s3.amazonaws.com/' + profile : profile;
                    }
                    return (
                        <div className="block">
                            <div className="extra_activitybox">
                                <div className="img-container" style={{ margin: '0 auto' }}>
                                    <img src={ProfileImg} />
                                </div>

                                <div className="attendance-name">

                                    <span>
  
                                        {`${currentChild.firstname} ${currentChild.lastname}`}
                                    </span>

                                    <div>
                                        {app?.new_childId}
                                    </div>
                                </div>


                                <div className="attendance-action">
                                    <div>
                                        <div
                                            onClick={() => {
                                                console.log('handleAttendance', app)
                                                handleAttendance(app, 'Present');
                                            }}
                                            style={style.attendanceAction}>
                                            <div
                                                className="circle-icon"
                                                style={{
                                                    ...style.circleIcon,
                                                    backgroundColor: app.attendance_status === 'Present' ? '#14e414' : 'gray',
                                                }}
                                            />
                                            Present
                                        </div>
                                    </div>

                                    <div>
                                        <div
                                            onClick={() => {
                                                handleAttendance(app, 'Absent');
                                            }}
                                            style={style.attendanceAction}>
                                            <div
                                                className="circle-icon"
                                                style={{
                                                    ...style.circleIcon,
                                                    backgroundColor: app.attendance_status === 'Absent' ? 'red' : 'gray',
                                                }}
                                            />
                                            Absent
                                        </div>

                
                                    </div>

                                    <div>
                                        <div
                                            onClick={() => {
                                                handleAttendance(app, 'Tardy');
                                            }}
                                            style={style.attendanceAction}>
                                            <div
                                                className="circle-icon"
                                                style={{
                                                    ...style.circleIcon,
                                                    backgroundColor: app.attendance_status === 'Tardy' ? '#f26e21' : 'gray',
                                                }}
                                            />
                                            Tardy
                                        </div>

                                    </div>
                                </div>

                                <div className="attendance-hours">
                                    <div className="field">
                                        <input
                                            type="number"
                                            onChange={e => {
                                                handleHours(app, e.target.value, 'volunteer_hours');
                                            }}
                                            name={'volunteer_hrs'}
                                            className={'field-input'}
                                            placeholder="Volunteer Hours"
                                            value={app?.volunteer_hours || 0}
                                        />
                                        <label className="field-label" for={`volunteer_hrs`}>
                                            Volunteer Hours
                                        </label>
                                    </div>
                                    <div className="field">
                                        <input
                                            type="number"
                                            onChange={e => {
                                                handleHours(app, e.target.value, 'mentoring_hours');
                                            }}
                                            name={'mentoring_hrs'}
                                            className={'field-input'}
                                            placeholder="Mentoring Hours"
                                            value={app?.mentoring_hours || 0}
                                        />
                                        <label className="field-label" for={`mentoring_hrs`}>
                                            Mentoring Hours
                                        </label>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                    <div style={{ padding: 12 }}>
                                        <div>
                                            <b>Time In</b>
                                        </div>
                                        <div style={{ fontSize: 14, fontStyle: 'italic' }}>
                                            {app.attendance_start_time ? militaryToRegularTime(app.attendance_start_time) : 'Not Clocked In'}
                                        </div>
                                    </div>
                                    <div style={{ padding: 12 }}>
                                        <div>
                                            <b>Time Out</b>
                                        </div>
                                        <div style={{ fontSize: 14, fontStyle: 'italic' }}>
                                            {app.attendance_end_time ? militaryToRegularTime(app.attendance_end_time) : 'Not Clocked Out'}
                                        </div>
                                    </div>
                                </div>

                                <div className="field" style={{ padding: 12 }}>
                                    <button
                                        className="timeoutBtn"
                                        onClick={() => {
                                            handleCheckOut(app);
                                        }}
                                        type="button"
                                    >
                                        Time Out
                                    </button>
                                </div>



                            </div>
                        </div>
                    );
                })}
            </div> */}

        {/* <div className="field actionBtn">
                <button
                    className="timeoutBtn"
                    onClick={handleCheckOutAll}
                    type="button"
                    style={{ width: '100%', backgroundColor: '#ff0e0e', color: 'white', }}
                >
                    End Session
                </button>
            </div> */}

        {/* <div className="field actionBtn">
                <button onClick={handleSubmit}>
                    {attendance.isAttendanceUpdateLoading ? 'Please Wait...' : 'Save'}
                </button>
            </div> */}


      </div>

      <Confirmation
        isVisible={isConfirmationVisible}
        message={
          <div>
            <div style={{ padding: 12, textAlign: "left" }}>
              <div>
                Do you want to save changes?
              </div>
              <div style={{ marginTop: 12 }}>
                <input
                  checked={markedAsAbsent}
                  type="checkbox"
                  onChange={(e) => {
                    setMarkedAsAbsent(e.target.checked);
                  }}
                />
                Mark all users absent that are not designated as Present or Tardy.
              </div>
            </div>
          </div>
        }
        toggleConfirmationVisible={setIsConfirmationVisible}
        onSubmit={() => {
          handleUpdateAttendance();
        }}
        submitButtonLabel="Submit"
      />

      {isImagePreviewModalVisible && (
        <ImagePreviewModal
          isImagePreviewModalVisible={isImagePreviewModalVisible}
          setIsImagePreviewModalVisible={setIsImagePreviewModalVisible}
          qrCodeUrl={currentQrCodeUrl}
        />
      )}
    </EventAttendeeStyled>
  );
};

const style = {
  attendanceAction: {
    cursor: "pointer",
  },
  attendanceSubAction: {
    marginTop: "8px",
    fontSize: "12px",
    //cursor: 'pointer',
    color: "grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    margin: "0 auto",
  },
  miniCircleIcon: {
    width: 10,
    height: 10,
    marginRight: "3px",
    marginLeft: "0",
  },
  preview: {
    cursor: "pointer",
    color: "blue",
    width: "100%",
  },
};
export default EventAttendee;
