/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-content {
    position:fixed;
    background: white;
    height: auto;
    top:25%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    width: 500px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.2);
    border-radius: 4px;
    outline: 0;
    margin: 0;
    padding: 0;
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0 solid rgba(120,130,140,.13);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;      
    align-items: center!important;
    background-color: lightsalmon;
  }
  
    .modal-title {
        margin: 0;
        line-height: 1.5;
    }
    .h4, h4 {
        font-size: 1.125rem;
    }

    .modal-header .btn-close {
        padding: .5rem .5rem;
        margin: -.5rem -.5rem -.5rem auto;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 500px;
            margin: 1.75rem auto;
        }
    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: .5rem;
        pointer-events: none;
    }

    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }

    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
        padding: .75rem;
        border-top: 0 solid rgba(120,130,140,.13);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .modal-footer>* {
        margin: .25rem;
    }

    .modal-content .btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #3e5569;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: .875rem;
        border-radius: 4px;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .modal-content .btn-primary {
        color: #fff;
        background-color: #3f9b47;
        border-color: #8898aa;
        box-shadow: 0 1px 0 rgb(255 255 255 / 15%);
    }

    .modal-content .btn-secondary {
        color: #fff;
        background-color: #8898aa;
        border-color: #8898aa;
        box-shadow: 0 1px 0 rgb(255 255 255 / 15%);
    }

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: 
        transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 2px;
    opacity: .5;
}


  .modal-content .grid-2a {
    display: grid;
    grid-template-columns: 4.33% 92.33%;
    grid-gap: 2.33333333%;
  }

  .modal-content .grid-2a .fa-clock {
      padding-top: 8px;
  }

  .modal-content .date-time-row {
      position: relative;
      display: flex;
      align-items: center;
  }

.modal-content .control-wrapper {
    margin-top: 10px;
}


.modal-content .react-tabs {
    margin-top: 10px;
}

.modal-content label.control-label {
    padding-right: 10px;
    vertical-align: top;
}

.modal-content textarea {
    width: 80%;
    height: 50px;
}
  .modal-content .tags-input {
      width: 200px;
  } */



.display-block {
  display: block;
}

.display-none {
  display: none;
}  
.modal-content .react-draggable {
    pointer-events: auto;
}
.modal-content.react-draggable h4 {
  margin: 0;
  font-size: 16px;
}
.modal-content.react-draggable .btn-close {
  position: relative;
  border: 0;
  height: 1em;
  color: #fff;
  padding: 0;
  font-size: 18px;
  margin-left: auto;
  background: transparent;
  transition: all .3s ease-in-out;
}
.modal-content.react-draggable .btn-close::after {
  content: '\0058';
}
.modal-content.react-draggable .btn-close:hover {
  opacity: 0.8;
}



/* Calendar Feed URL */
.modal-content.calendar-url .modal-body div.row,
.modal-content.calendar-url .modal-body div.row form,
.modal-content.calendar-url .modal-body div.row form input {
  width: 100% !important;
}
.modal-content.calendar-url .modal-body div.row {
  margin: 1rem 0;
  background: #dcdcdc24;
  border: 1px solid gainsboro;
}
.modal-content.calendar-url .modal-body div.row form {
  padding: .5rem;
}
.modal-content.calendar-url .modal-body div.row form input {
  border: none !important;
  height: unset !important; 
  background: transparent !important;
}
.modal-content.calendar-url .modal-body div.row #copyButton {
  border: none;
  width: 38px;
  color: #fff;
  background: #3788d8;
}
.modal-content.calendar-url .modal-footer {
  padding: 1rem;
}
.modal-content.calendar-url .modal-footer button {
  color: white;
  font-size: 0.8em !important;
  border: none;
  background-color: #f26e21;
  padding: 10px;
  display: block;
  width: 150px;
  margin: 10px auto;
  box-shadow: 0px 3px 6px #908e8e;
  border-radius: 40px !important;
}


/* Calendar Event Schedule */
.modal-content.calendar-event-schedule .row {
  padding: .5rem 0;
}
.modal-content.calendar-event-schedule .row label {
  color: grey;
}


.modal-content.calendar-event-schedule .modal-footer {
  padding: 1rem;
}
.modal-content.calendar-event-schedule .modal-footer button {
  color: white;
  font-size: 0.8em !important;
  border: none;
  background-color: #f26e21;
  padding: 10px;
  display: block;
  width: 150px;
  margin: 10px auto;
  box-shadow: 0px 3px 6px #908e8e;
  border-radius: 40px !important;
}


/* Calendar Edit */
.modal-content.calendar-edit-activity {
  max-width: 520px;
}
.modal-content.calendar-edit-activity .react-tabs {
  margin-top: 1rem;
}
.modal-content.calendar-edit-activity .react-tabs .react-tabs__tab-list {
  margin: 0 0 15px;
}
.modal-content.calendar-edit-activity .react-tabs .react-tabs__tab--selected {
  position: relative;
  border: none;
  color: #ec6e33;
  border-bottom: 1px solid #ec6e33;
}
.modal-content.calendar-edit-activity .react-tabs .react-tabs__tab--selected:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.65px;
  background-color: #ec6e33;
}

.modal-content.calendar-edit-activity .field {
  position: relative;
  margin-top: 2rem;
  padding-bottom: 1rem;
}
.modal-content.calendar-edit-activity label  {
  position: absolute;
  top: -1.6rem;
  color: gray;
  font-size: 14px;
}
.modal-content.calendar-edit-activity input  {
  width: 100%;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid lightgrey;
}

.modal-content.calendar-edit-activity .field textarea {
  width: 100%;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid lightgrey;
}
.modal-content.calendar-edit-activity .field.select-wrapper {
  margin-top: 3rem;
}
.modal-content.calendar-edit-activity .field select {
  width: 100%;
  border: none;
  font-size: 18px;
  margin-bottom: .5rem;
  border-bottom: 2px solid lightgrey;
}


.modal-content.calendar-edit-activity input:hover,
.modal-content.calendar-edit-activity .field textarea:hover,
.modal-content.calendar-edit-activity .field select:hover {
  border-bottom: 2px solid #ec6e33 !important;
}

.modal-content.calendar-edit-activity .field-calendar {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}
.modal-content.calendar-edit-activity .field-calendar .icon-clock {
  width: 32px;
}
.modal-content.calendar-edit-activity .field-calendar .icon-clock svg {
  color: #ec6e33;
}
.modal-content.calendar-edit-activity .field-calendar .date-time-row {
  display: flex;
  align-items: center;
}
.modal-content.calendar-edit-activity .field-calendar .date-time-row {
  display: flex;
  align-items: center;
}
.modal-content.calendar-edit-activity .field-calendar .date-time-row >div:not(:last-child) {
  margin-right: .5rem;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #ec6e33 !important;
}

.modal-content.calendar-edit-activity .modal-footer {
  display: flex;
  padding: 0 1rem 1rem;
  justify-content: center;
}
.modal-content.calendar-edit-activity .modal-footer .btn-primary {
  color: white;
  border: none;
  padding: 10px;
  display: block;
  width: 150px;
  margin:  0 8px;
  background-color: #f26e21;
  font-size: 0.8em !important;
  border-radius: 40px !important;
  box-shadow: 0px 3px 6px #908e8e;
}
.modal-content.calendar-edit-activity .modal-footer .btn-delete {
  color: white;
  border: none;
  padding: 10px;
  display: block;
  width: 150px;
  margin:  0 8px;
  background-color: #DC3C45;
  font-size: 0.8em !important;
  border-radius: 40px !important;
  box-shadow: 0px 3px 6px #908e8e;
}