import styled from "styled-components";

export const ConfirmationModal = styled.div`
  padding-top: 20%;
  .yes-btn {
    background-color: ${({ theme }) => theme.button.backgroundColor.primary};
    padding: 10px;
    display: block;
    margin: 20px auto;
    width: 30%;
    color: white;
    border-radius: ${({ theme }) => theme.button.borderRadius} !important;
    border: none;
    box-shadow: 0px 3px 6px #908e8e;
    height: fit-content;
    margin-right: 15%;
  }
  .cancel-btn {
    font-size: ${({ theme }) => theme.button.fontSize} !important;
    background-color: lightgrey;
    border: none;
    box-shadow: 0px 3px 6px #908e8e;
    border-radius: ${({ theme }) => theme.button.borderRadius} !important;
  }
  .cancel-btn {
    padding: 10px;
    width: 30%;
    display: block;
    margin: 20px auto;
    border: none;
    color: white;
    margin-left: 15%;
  }
  .modal-content {
    width: 20%;
    border: none !important;
    height: auto;
  }
  .profile-confirmation {
    text-align: center;
    margin-top: 20px;
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  }

  .close {
    font-size: 24px !important;
    cursor: pointer;
  }

  @media screen and (max-width: 1920px) {
    .modal-content {
      width: 25%;
      border: none !important;
      height: auto;
      border-radius: 10px !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .modal-content {
      width: 25%;
      border: none !important;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    .modal-content {
      width: 85%;
      border: none !important;
      height: auto;
    }
  }

  .vendor-list div {
    margin: 12px;
  }
`;
