import { call, take, put } from "redux-saga/effects";
import graphqlClient from "../../graphql";

import * as actionType from "./Constant";

import {
  ADD_VENDORS_APP_GROUP,
  DELETE_VENDORS_APP_GROUP,
  UPDATE_VENDORS_APP_GROUP,
  GET_VENDOR_APP_GROUP,
  UPDATE_VENDORS_SUB_GROUP,
  GET_SUB_GROUP_BY_VENDOR,
  DELETE_VENDOR_SUB_GROUP,
  ADD_UPDATE_GROUP_WITH_SUB_GROUP,
  MULTIPLE_GROUP_UPDATE
} from "../../graphql/vendorMutation";


import { requestUserGroup } from './Groups';

export const getVendorApplicationGroupFromDatabase = vendor => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.query({
        query: GET_VENDOR_APP_GROUP,
        variables: { vendor }
      });
      console.log('getVendorApplicationGroupFromDatabase data', data)
      return resolve(data.getAllFormAppGroupsByVendor);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
}


const addVendorApplicationGroupToDatabase = appGroup => {
  console.log("appGroup", appGroup);
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.mutate({
        mutation: ADD_VENDORS_APP_GROUP,
        variables: { appGroup }
      });

      return resolve(data.addVendorAppGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const editVendorApplicationGroupToDatabase = appGroup => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("editVendorApplicationGroupToDatabase appGroup", appGroup);
      const { data } = await graphqlClient.mutate({
        mutation: UPDATE_VENDORS_APP_GROUP,
        variables: { appGroup }
      });

      return resolve(data.editVendorAppGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const deleteVendorApplicationGroupToDatabase = (ap) => {
  return new Promise(async (resolve, reject) => {
    try {
      const appGroup = {
        ...ap
      };
      const { data } = await graphqlClient.mutate({
        mutation: DELETE_VENDORS_APP_GROUP,
        variables: { appGroup }
      });
      console.log("data.deleteVendorAppGroups", data.deleteVendorAppGroup);
      return resolve(data.deleteVendorAppGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const requestAddVendorAppGroup = appGroup => {
  return {
    type: actionType.REQUEST_ADD_VENDOR_APP_GROUP,
    appGroup: appGroup
  };
};

export function setUserGroups(data) {
  return {
    type: actionType.SET_USER_GROUPS,
    data
  };
}

export function* addVendorAppGroup({ appGroup }) {
  try {
    const response = yield call(addVendorApplicationGroupToDatabase, appGroup);
    yield put(setUserGroups(response));
  } catch (error) {
    console.log("error", error);
  }
}

export const requestEditVendorAppGroup = appGroup => {
  return {
    type: actionType.REQUEST_EDIT_VENDOR_APP_GROUP,
    appGroup
  };
};

export function* editVendorAppGroup({ appGroup }) {
  try {
    const response = yield call(editVendorApplicationGroupToDatabase, appGroup);
    yield put(setUserGroups(response));
  } catch (error) {
    console.log("error", error);
  }
}

export const requestDeleteVendorAppGroup = appGroup => {
  return {
    type: actionType.REQUEST_DELETE_VENDOR_APP_GROUP,
    appGroup
  };
};

export function* deleteVendorAppGroup({ appGroup }) {
  try {
    const response = yield call(
      deleteVendorApplicationGroupToDatabase,
      appGroup
    );
    yield put(setUserGroups(response));
  } catch (error) {
    console.log("error", error);
  }
}

export const requestCreateUpdateSubGroup = subgroup => {
  return {
    type: actionType.REQUEST_CREATE_UPDATE_SUBGROUP,
    subgroup
  };
}

export function* addUpdateSubGroup(data) {
  try {
    yield call(editVendorApplicationSubGroupToDatabase, data.subgroup);
    yield put(requestSubGroupByVendor(data.subgroup.vendor_id));
  } catch (error) {
    console.log("error", error);
  }
}


const editVendorApplicationSubGroupToDatabase = subGroup => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.mutate({
        mutation: UPDATE_VENDORS_SUB_GROUP,
        variables: { subGroup }
      });

      return resolve(data.createUpdateVendorSubGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const requestSubGroupByVendor = vendorId => {
  return {
    type: actionType.REQUEST_SUB_GROUP_BY_VENDOR,
    vendorId
  };
}

export function* getSubGroupByVendor(data) {
  try {
    const response = yield call(getSubGroupByVendorFromDatabase, data.vendorId);
    yield put(setVendorSubGroup(response))
  } catch (error) {
    console.log("getSubGroupByVendor error", error);
  }
}


const getSubGroupByVendorFromDatabase = vendorId => {

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.query({
        query: GET_SUB_GROUP_BY_VENDOR,
        variables: { vendor_id: vendorId }
      });

      return resolve(data.getSubGroupByVendor);
    } catch (error) {
      console.log("getSubGroupByVendorFromDatabase error", error);
      reject(error);
    }
  });
};


export function setVendorSubGroup(data) {
  return {
    type: actionType.SET_VENDOR_SUB_GROUPS,
    data
  };
}


export const requestDeleteVendorSubGroup = ({
  vendorId,
  subGroupId
}) => {
  return {
    type: actionType.REQUEST_DELETE_VENDOR_SUB_GROUP,
    vendorId,
    subGroupId
  };
}

export function* deleteSubGroupByVendor(data) {
  try {
    yield call(deleteVendorSubGroupFromDatabase, {
      vendor_id: data.vendorId,
      sub_group_id: data.subGroupId

    });

    yield put(requestSubGroupByVendor(data.vendorId));
  } catch (error) {
    console.log("getSubGroupByVendor error", error);
  }
}

const deleteVendorSubGroupFromDatabase = ({
  vendor_id,
  sub_group_id
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.mutate({
        mutation: DELETE_VENDOR_SUB_GROUP,
        variables: {
          subGroup: {
            vendor_id,
            sub_group_id
          }
        }
      });

      return resolve(data.createUpdateVendorSubGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};



export const addUpdateGroupWithSubGroup = ({
  groups,
  user
}) => {
  return {
    type: actionType.REQUEST_CREATE_UPDATE_GROUP_WITH_SUB_GROUP,
    groups,
    user
  };
}

export function* editGroupWithSubGroups(groupWithSubGroup) {
  try {

    yield call(addGroupWithAppGroupToDatabase, groupWithSubGroup.groups);

    if (groupWithSubGroup?.user?.email) {
      yield put(requestUserGroup(groupWithSubGroup.user.email));
    }
    if (groupWithSubGroup?.groups?.vendor_id) {
      yield put(requestSubGroupByVendor(groupWithSubGroup.groups.vendor_id));
    }
  } catch (error) {
    console.log("getSubGroupByVendor error", error);
  }
}



const addGroupWithAppGroupToDatabase = groupWithSubGroup => {

  return new Promise(async (resolve, reject) => {

    console.log('addGroupWithAppGroupToDatabase', groupWithSubGroup)
    try {
      const { data } = await graphqlClient.mutate({
        mutation: ADD_UPDATE_GROUP_WITH_SUB_GROUP,
        variables: { groupWithSubGroup }
      });
      console.log('addGroupWithAppGroupToDatabase data', data)
      return resolve(data.groupWithSubGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};


export function requestUpdateMultipleGroup(data) {
  return {
    type: actionType.REQUEST_UPDATE_MULTIPLE_GROUP,
    data
  };
}

export function* editMultipleGroup(action) {
  try {
    yield call(updateMultipleGroupToDatabase, action.data.groups);
    if (action.data?.user?.email) {
      yield put(requestUserGroup(action.data?.user?.email));
    }
    if (action.data?.groups?.vendor_id) {
      yield put(requestSubGroupByVendor(action.data.groups?.vendor_id));
    }
  } catch (error) {
    console.log("getSubGroupByVendor error", error);
  }
}

const updateMultipleGroupToDatabase = multipleGroup => {

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await graphqlClient.mutate({
        mutation: MULTIPLE_GROUP_UPDATE,
        variables: {
          groupWithSubGroup: multipleGroup
        }
      });
      return resolve(data.groupWithSubGroup);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

