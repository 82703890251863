

export const exportHeaders = {
    class_teacher: 'Group(s)',
    application_date: 'Application Date',
    archived_date: 'Archived Date',
    id: 'Id',
    verification: 'Verification',
    vendor: 'Vendor',
    student_status: 'Student Status',
    section1_date_signed: 'Terms and Waiver 1 Date Signed',
    section2_date_signed: 'Terms and Waiver 2 Date Signed',
    section3_date_signed: 'Terms and Waiver 3 Date Signed',
    section1_name: 'Terms and Waiver 1 Signed By',
    section2_name: 'Terms and Waiver 2 Signed By',
    section3_name: 'Terms and Waiver 3 Signed By',
    section1_text: 'Terms and Waiver Context 1',
    section2_text: 'Terms and Waiver Context 2',
    section3_text: 'Terms and Waiver Context 3',
    color_designation: 'Color Designation',
    notes: 'Notes',
    emergency_contacts: {
        first_name: 'Firstname',
        gender: 'Gender',
        last_name: 'Lastname',
        mobile_phone: 'Mobile Phone',
        relationship_to_child: 'Relationship to Child',
        work_phone: 'Work Phone',
    },
    child: {
        main: {
            firstname: 'Firstname',
            lastname: 'Lastname',
            nickname: 'Nickname',
            age: 'Age',
            gender: 'Gender',
            phone_type: 'Phone Type',
            phone_number: 'Phone Number',
            email_type: 'Email Type',
            email_address: 'Email Address',
            phone_type2: 'Phone Type 2',
            phone_number2: 'Phone Number 2',
            email_type2: 'Email Type 2',
            email_address2: 'Email Address 2',
            birthdate: 'Birthdate',
            address: 'Address',
            city: 'City',
            state: 'State',
            zip_code: 'Zip Code',
            location_site: 'Location Site',
            ethnicities: 'Ethnicities',
            programs: 'Programs',
            school_name: 'School Name',
            school_phone: 'School Phone',
            year_taken: 'Year started as Mentee',
            hobbies: 'Hobbies / Personal Interests',
            life_events: 'Life events you would like to share',
            career_goals: 'Career Goals',
            colleges: 'List of Colleges you are Considering Upon Graduation',
            affiliations: 'Group and Other Team Affiliations',
            awards: 'List of Awards',
            accomplishments: 'List of Accomplishments',
            reason_suspended: 'Reason',
            has_suspended: 'Are there currently any problems with your child either at home or at school?',
            mentee_gain_program: 'What does the mentee hope to gain from the program?',
            child_lives_with: 'Child lives with',
            hospital_phone: 'Hospital Phone',
            hospital_preference: 'Hospital Preference',
            doctor_name: 'Doctor Name',
            doctor_phone: 'Doctor Phone',
            gpa_cumulative_q1: 'GPA Cumulative Q1',
            gpa_cumulative_q2: 'GPA Cumulative Q2',
            gpa_cumulative_q3: 'GPA Cumulative Q3',
            gpa_cumulative_q4: 'GPA Cumulative Q4',
            gpa_cumulative_year: 'GPA Cumulative Year',
            gpa_quarter_year: 'GPA Quarter Year',
            gpa_quarter_q1: 'GPA Quarter 1',
            gpa_quarter_q2: 'GPA Quarter 2',
            gpa_quarter_q3: 'GPA Quarter 3',
            gpa_quarter_q4: 'GPA Quarter 4',
            class_rank: 'Class Rank',
            grade_number: 'Grade',
            grade_desc: 'Grade Name',
            new_childId: 'Child Id'
        },
        daycare: {
            firstname: 'Firstname',
            lastname: 'Lastname',
            nickname: 'Nickname',
            age: 'Age',
            gender: 'Gender',
            needed_days: 'Needed Days',
            schedule_tour: 'Schedule Tour',
            voucher: 'Voucher',
            address: 'Address',
            city: 'City',
            state: 'State',
            zip_code: 'Zip Code',
            current_classroom: 'Current Classroom',
            preffered_start_date: 'Preffered Start Date',
            prev_school_address: 'Previous School Address',
            prev_school_attended: 'Previous School Attended',
            prev_school_city: 'Previous School City',
            prev_school_phone: 'Previous School Phone',
            prev_school_state: 'Previous School State',
            prev_school_zip_code: 'Previous School Zip Code',
            primary_language: 'Primary Language',
            reason_suspended: 'Reason',
            has_suspended: 'Are there currently any problems with your child either at home or at school?',
            is_child_transferring: 'Is your child transferring from a previous daycare?',
            history_prev_diseases: 'Please list any history of significant previous diseases, including convulsions, heart trouble and diabetes.',
            child_currently_doctors_care: 'Is your child currently under doctors care?',
            reasons_previous_hospitalizations: 'Please list the date and reason(s) for any previous hospitalizations and/or surgeries.',
            comments_suggestion: 'Any comments or suggestions regarding your child that may help us.',
            list_special_dietary: 'Please list any special dietary concerns.',
            list_any_allergies: 'Please list any allergies that your child may have.',
            mental_physical_disabilities: 'Does the child have any physical or mental disabilities?',
            medical_action_plan: 'Is there a need for a medical action plan?',
            list_fears_unique_behavior: 'List any particular fears or unique behavior characteristics the child has.',
            does_child_require_physical_education_service: 'Does your child required additional physical / educational services',
            transfer_reason: 'Transfer Reason',
            child_lives_with: 'Child lives with',
            hospital_phone: 'Hospital Phone',
            hospital_preference: 'Hospital Preference',
            doctor_name: 'Doctor Name',
            doctor_phone: 'Doctor Phone',
            programs: 'Programs',
            ethnicities: 'Ethnicities',
            birthdate: 'Birthdate',
            new_childId: 'Child Id'
        }
    },
    parent: {
        address: 'Address',
        age: 'Age',
        birthdate: 'Birthdate',
        child_hs_grad: 'How important is it to you that your child graduates from high school?',
        child_col_grad: 'How important is it to you that your child attends college?',
        city: 'City',
        email_address: 'Email Address',
        email_address2: 'Email Address 2',
        email_type: 'Email Type',
        email_type2: 'Email Type 2',
        employers_name: 'Employers Name',
        ethnicities: 'Ethnicities',
        firstname: 'Firstname',
        gender: 'Gender',
        lastname: 'Lastname',
        level_of_education: 'What is your highest level of education?',
        live_area: 'How long have you lived in this area?',
        occupation: 'Occupation',
        parent_goals: 'What are some of your expectations from the Mentoring Program?',
        parent_child_goals: 'Why are you referring your child to our program?',
        person_recommend: 'How did you find us?',
        phone_number: 'Phone Number',
        phone_number2: 'Phone Number 2',
        phone_type: 'Phone Type',
        phone_type2: 'Phone Type 2',
        state: 'State',
        zip_code: 'Zip Code',
        new_parentId: 'Parent Id'
    }
}

export const getChildFromFormData = formData => {
    const parentData = formData.find(item => {
      const label = item.label.toLowerCase();
      return  label.includes('name');
    });
    const parentFields = parentData ? parentData.fields.filter(item => item.label === 'First Name' || item.label === 'Last Name') : [];
    return parentFields.length > 0 ? `${parentFields[0].value.replaceAll('"',"")} ${parentFields[1].value.replaceAll('"',"")}` : ''
  }
  