.wizard-wrapper {
  margin: 30px auto;
}

.wizard-wrapper .wizard-inner {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
}

.wizard-wrapper .connecting-line {
  height: 3px;
  background: #e0e0e0;
  position: absolute;
  width: 78%;
  margin: 0 auto;
  left: 0;
  right: 0%;
  top: 28%;
  z-index: 1;
}

.wizard-wrapper .nav-tabs {
  position: relative;
  margin: 0 auto;
  margin-bottom: 0;
  border-bottom: none;
  list-style: none;
  padding-left: 0;
}

.wizard-wrapper .nav-tabs:after {
  content: " ";
  display: table;
  clear: both;
}

.nav-tabs > li {
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 25%;
  float: left;
  text-align: center;
}

#daycare-ul > li {
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 20%;
  float: left;
  text-align: center;
}

.wizard-wrapper .nav-tabs > li a {
  padding: 0;
  border: none;
  text-decoration: none;
}

.wizard-wrapper .nav-tabs > li.active > a,
.wizard-wrapper .nav-tabs > li.active > a:hover,
.wizard-wrapper .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

.wizard-wrapper .round-tab {
  width: 50px;
  height: 50px;
  line-height: 48px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: table;
  border-radius: 100px;
  z-index: 2;
  position: relative;
  left: 0;
  font-size: 20px;
  transition: all 0.5s ease;
  border: 2px solid #f26e21;
  background: white;
  color: #f26e21;
}

.wizard-wrapper .round-tab:hover {
  background-color: #f47b2c;
  color: white;
  transition: all 0.5s ease;
}

.wizard-wrapper li.active .round-tab {
  border: 2px solid;
}

.wizard-wrapper .nav-tabs > li.active .round-tab {
  border-color: #f26e21;
  background: #f47b2c;
  color: white;
}

.wizard-inner .round-tab-title {
  color: #f26e21;
  font-weight: 600;
  font-size: 20px;
}

.wizard-inner .nav-tabs > li.active .round-tab-title {
  color: #f26e21;
}

.container {
  margin-right: auto;
  margin-left: auto;
  /* padding-left: 15px;
  padding-right: 15px; */
  width: 100%;
  padding-top: 25px;
  position: relative;
}

@media (min-width: 768px) {
  .container {
    max-width: 850px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1070px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

.heading {
  color: #f26e21;
  border-bottom: 4px solid #f26e21;
  width: fit-content;
  padding-bottom: 6px;
  /* padding-left: 15px; */
  font-size: 27px;
  margin: 0;
  display: inline-block;
}

.child-info-wrapper,
.general-info-wrapper,
.medical-info-wrapper,
.emergency-contact-wrapper,
.waiver-wrapper,
.relationship-wrapper {
  box-shadow: 0px 0px 30px #ccc;
  padding: 30px;
  margin-top: 30px;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 70px;
}

.parent-info-wrapper {
  box-shadow: 0px 0px 30px #ccc;
  padding: 30px;
  margin-top: 30px;
  background-color: #fff;
  padding-bottom: 70px;
}

.child-info-wrapper {
  padding-bottom: 30px;
}
.emergency-contact-wrapper {
  padding-bottom: 30px;
}
.emergency-contact-wrapper > div {
  overflow-x: auto;
}
.emergency-contact-wrapper > div::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
.emergency-contact-wrapper > div::-webkit-scrollbar-track {
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  background: rgb(243 110 34 / 20%);
}
.emergency-contact-wrapper > div::-webkit-scrollbar-thumb {
  background: #f36e22;
  border-radius: 10px;
}

.child-info-wrapper > div,
.general-info-wrapper > div,
.medical-info-wrapper > div,
.parent-info-wrapper > div,
.emergency-contact-wrapper > div,
.waiver-wrapper > div {
  padding-left: 15px;
  padding-right: 15px;
}

.cus-select-container {
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px !important;
  margin-right: 20px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cus-select-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.cus-select-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: white;
  border: 3px solid #4b525a;
  border-radius: 50%;
}

.cus-select-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #4b525a;
}

.cus-select-container input:checked ~ .checkmark:after {
  display: block;
}

.cus-checkbox-container {
  position: relative !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 30px auto 0 auto;
  margin-bottom: 0px;
  padding-left: 20px;
  font-size: 22px;
  cursor: pointer;
}

.cus-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.cus-checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-color: black;
  border-radius: 0px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.cus-checkbox-container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  content: "";
  position: absolute;
  display: none;
}

.cus-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.hide {
  display: none;
}

.agree-text {
  font-size: 18px;
  padding-left: 15px;
  color: #4b525a;
  font-weight: bold;
  margin-bottom: 20px;
}

.style-eight {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: medium double #f26e21;
  color: #f26e21;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0;
}

.style-eight::after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: none;
}

.application-btn-container {
  margin-top: 40px;
  text-align: center;
}

.application-btn-container a,
.application-btn-container button {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
  text-decoration: none;
  cursor: pointer;
  background: #f26e21;
  color: white;
  border-radius: 25px;
  padding: 12px 25px;
  display: inline-block;
  font-size: 16px;
}

.application-btn-container a > span {
  margin-left: 5px;
}

.application-btn-container button.right,
.application-btn-container a.right {
  float: right;
}

.application-btn-container a.left {
  float: left;
  margin-right: 16px;
}

.application-btn-container a.remove {
  background: red !important;
}

.application-btn-container:after {
  content: " ";
  display: table;
  clear: both;
}

.wizard-wrapper .nav-tabs > li.disabled a {
  pointer-events: none !important;
}

.wizard-wrapper .nav-tabs > li.disabled .round-tab {
  background: #e0e0e0 !important;
  color: white !important;
  border: none !important;
}

.wizard-wrapper .nav-tabs > li.disabled .round-tab-title {
  color: #abaaaa !important;
}

.disabled-link {
  pointer-events: none !important;
  background: gray !important;
}

/* #applicationForm select.field-input {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
} */

@media print {
  body {
    margin: 100;
  }
  #applicationForm .form-group {
    margin-bottom: 0px !important;
  }
  #applicationForm {
    font-size: 1px !important;
  }
}
#applicationForm .select-field-wrapper {
  position: relative;
}
#applicationForm .select-field-wrapper:after {
  content: "\f078";
  position: absolute;
  right: 0;
  bottom: 18px;
  font-size: 12px;
  color: #555 !important;
  font-family: "fontawesome";
  pointer-events: none;
}
#applicationForm .select-field-wrapper select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

#applicationForm .form-group .form-control {
  width: calc(100% - 18px);
}

/* datepicker */
.datepicker-btn {
  padding: 0;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  color: #fff;
  border: none;
  box-shadow: none;
  background: transparent;
  font-size: unset !important;
  border-radius: 100% !important;
}
.datepicker-btn svg {
  width: 100% !important;
  height: 75% !important;
}
.datepicker-btn:hover {
  background: rgb(255 255 255 / 20%);
  transition: 0.15s ease-in-out;
}

.react-datepicker__header {
  padding-top: 0;
  border-bottom: none;
}
.react-datepicker__header select {
  color: #fff;
  margin: 0 5px;
  border: none;
  -webkit-appearance: menulist-text;
  background: rgb(255 255 255 / 15%) !important;
}
.react-datepicker__header select option {
  color: #555 !important;
}
.react-datepicker {
  border: 1px solid #ddd;
}
.react-datepicker__triangle {
  border-bottom-color: #f46d22 !important;
}
.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-datepicker__day-names {
  padding: 5px 0;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: rgb(0 0 0 / 75%);
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  color: #fff !important;
}
.react-datepicker__day--outside-month {
  color: rgb(0 0 0 / 35%);
}
