import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
  faMinus,
  faTimes,
  faCheck,
  // faDownload,
  // faSearch,
  faChevronLeft,
  faChevronRight,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
// import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// import { format, isWithinInterval, parseISO } from "date-fns";

// import ViewToggleButton from "../../../../helpers/ViewToggleButton";

import StudentIcon from "../../../../constants/icons/users.svg";
import AttendanceCheck from "../../../../constants/icons/attendance-check.svg";
import AttendanceLate from "../../../../constants/icons/attendance-late.svg";
import AttendanceAbsent from "../../../../constants/icons/attendance-absent.svg";
import CalendarIcon from "../../../../constants/icons/calendar.svg";
import HelpCircle from "../../../../images/help-circle.svg";
import CircleCheck from "../../../../images/circle-check.svg";
import CircleLate from "../../../../images/circle-minus.svg";
import CircleExLate from "../../../../images/circle-ex-late.svg";
import CircleAbsent from "../../../../images/circle-error.svg";
import CircleExAbsent from "../../../../images/circle-ex-absent.svg";
import ClearIcon from "../../../../constants/icons/attendance-absent.svg";
import ModalImport from "../../../../helpers/ModalImport";
// import UserImg from "../../../../constants/icons/user-img.svg";
// import EditIcon from "../../../../constants/icons/edit.svg";

import { getNameFromCustomForm } from "../../Grades/utils";


const EventAttendeeListStyled = styled.div`

    padding: 15px;
    background-color: white;
    border-radius: 10px;
    

    #event-attendee-table {
        width: 100%;
        border-collapse: collapse;
    }

    .event-attendee-header, .event-summary-header {
        background-color: #FC9A3B;
        height 40px;
        padding: 12px !important;
        padding: 15px;
    }

      
    .event-attendee-header th:nth-child(1), .event-summary-header th:nth-child(1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-align: left;
    }


    .event-attendee-header th:nth-child(7), .event-summary-header th:nth-child(3) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    #event-attendee-table tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

      
    #event-attendee-table tr:nth-child(even){
        background-color: #f4f4f5;
    }
 
    #event-attendee-table tr:nth-child(odd) {
        background-color: white;
    }


    #event-attendee-table tr td:not(:first-child) {
        text-align: center;
    }

    #event-attendee-table td,
    #event-attendee-table th {
        border: 0;
        padding: 15px;
    }

    #event-attendee-table th {
        padding-left: 15px;
    }

    .attendance-icon {
        width: 30px;
        height: 30px;
        border: 1px solid #6C6C6C;
        background-color: #f4f4f5;
        margin: 0 auto;
        border-radius: 50%;
        padding-top: 1.5px;
    }

    .event-input {
        padding: 8px 16px;
        width: 40px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    
    .attendance-input-hours {
        padding: 8px 16px;
        width: 25px;
        align-items: center;
        text-align: center;
        border: 1px solid #cacacb;
    }

    .attendance-input-btn {
        position: relative;
        top: 2px;
        background-color: white;
        border: 1px solid #cacacb;
        margin-top: 5px;
        height: 32.8px;
    }
    .attendance-input-dec {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 0px;
    }

    .attendance-input-inc {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: 0px;
    }

    .attendance-present { 
        background-color: #F0F9EE;
        border: 1px solid #3B7D2A;
        color: #3B7D2A;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-late { 
        background-color: #FFE3CF;
        border: 1px solid #EF8722;
        color: #EF8722;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-absent { 
        background-color: #FDE6E6;
        border: 1px solid #EB0000;
        color: #EB0000;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-present > svg,
    .attendance-late > svg,
    .attendance-absent > svg {
        margin: 0 auto;
    }
    
    .attendance-excused { 
        font-size: 12px;
        height: 15px;
        margin: 0 auto;
        color: #6C6C6C;
    }

    .attendance-present-container {
        position: relative;
        top: 0px;
    }
    
    
    .link-text {
        font-weight: bold;
        color: #18A0FB !important;
        text-decoration: none;
    }

    .empty-name {
        width: 70px;
        text-align: center;
    }

    .summary-container {
       background-color: #FBFBFB; 
       border: 1px solid #DEDEDE;
       width: 22%; 
       padding: 12px;
       border-radius: 10px;
       display: flex;
       flex-direction: row;
    }

    .summary-icon {
        width: 32px;
        height: 32px
    }

    .summary-text {
        color: #6C6C6C;
    }
    .summary-details-container {
        margin-left: 12px;
    }
    
    .toggle-list-container {
        display: flex; 
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #F4F4F5;
        border: 1.5px solid #CACACB;
        border-radius: 50px;
        padding: 2px;
        color: #000;
    }

    #import-button {
      position: relative;
      top: 22px;
      background-color: white;
      border: 1.8px solid #CACACB;
      border-radius: 8px;
      margin-left: 12px;
      width: 120px;
      padding: 10px;
    }

    #import-button {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      color: #1B0B03 !important;
    }

    .import-button {
      position: relative;
      top: 22px;
      background-color: white;
      border: 1.8px solid #CACACB;
      border-radius: 8px;
      margin-left: 12px;
      width: 120px;
	}

    .filter-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-top: 12px;
        margin-bottom: 12px;
    }

    .filter-container .react-datepicker-wrapper {
      margin: 0 10px 0 0;
    }

    .filter-input-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .input-filter {
      padding: 8px 16px;
      align-items: center;
      border: 1.8px solid #dedede;
      border-radius: 8px !important;
    }

    .input-search-full {
      width: 100% !important;
        height: 18px !important;
        padding: 8px 16px;
        align-items: center;
    }

    .input-icons {
      // width:150px;
      position: relative;
      display: flex;
      align-items: center;
  }

    .input-search {
      width: 16px !important;
      height: 15px !important;
      padding: 8px 16px;
      align-items: center;
    }

    .filter-input-container > input {
        margin-right: 3px;
        width:80px;
    }

    .attendance-summary-container {
        display: flex; 
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .attendance-hours-container {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start;
        gap: 30px;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .toggle-list-container > .btn-group-type-toggle {
        text-align: center;
        width: 45%;
        padding: 8px;
        border-radius: 50px;
        cursor: pointer;
        color: #000;
    }
    .btn-group-type-toggle-selected {
        background-color: #FC9A3B;
        font-weight: 600;
    }

    .event-date-picker {
        position: relative;
        width: 80px;
        height: 16px;
    }

    .user-details {
        text-align: left;
        position: relative;
        top: 5px;
        left: 12px;
    }

    .user-id { 
        color: #6C6C6C
    }

    .select-filter {
        width: 110px;
        height: 33px;
        margin-right: 5px;
        position:relative;
        top: 1px;
    }

 
    .search-input {
        width: 80px !important;
    }

    .btn-search {
        background-color: white;
		border: 1px solid #dedede;
        height: 33px;
        margin-right: 4px;
        border-radius: 8px;
        position:relative;
        top: 1px;
        
    }

    .date-filter {
        height: 14.4px;
    }
 
    input[type=checkbox] {
        -webkit-appearance:none;
        width: 17px;
        height: 17px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid rgba(220,220,225,1);
        border-radius: 2px;
    }
  
    input[type=checkbox]:after {
        content: "";
        display: inline-block;
        position: relative;
        top: -1px;
        left: 4px;
        width: 5px;
        height: 7px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        -webkit-transform: rotate(45deg);
    }
      
    input[type=checkbox]:checked   {
          background: #6c6c6c;
          outline: none;
          border: none;
    }

      input:focus,input:active {
          outline: none;
    }

    @media (max-width: 768px) {
        .attendance-summary-container {
            display: flex; 
            flex-direction: column;
        }
        .summary-container {
            width: 97%;
        }
        .filter-container {
            flex-direction: column;
        }
    }

    .input-pagination {
        padding: 8px 16px;
        align-items: center;
        border: 1.8px solid #dedede;
        border-radius: 8px !important;
    }
  
    .btn-view-page-container {
        background-color: white;
        border: 2px solid #dedede;
        border-radius: 12px;
        overflow: hidden;
      
      }
      
    .btn-view-page-container button {
        background-color: white !important;
    }

    .tooltip-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;    
      margin-top: 12px;
    }

    .password-container {
      display: flex;
      align-items: center;
    }

    .password-icon {
      position: relative;
      left: 16px;
      bottom: 11px;
      transform: translateY(90%);
      cursor: pointer;
      color: #6c6c6c;
  
      margin-bottom: 0px;
    }

    .label-unique-id {
      color: #6C6C6C;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      margin-top: 5px;
    }

    .name-container {
      height: 19px;
    }
`;

// const DateCustomInput = ({ value, onClick, name, className, placeholder, label }) => (
//     <div className="input-icons">
//         <input
//             value={value}
//             onClick={onClick}
//             name={name}
//             className={`${className} event-date-picker event-input`}
//             placeholder={label}
//             readOnly={true}

//         />
//     </div>
// );

const STUDENT_DATA = [
  {
    name: "Alex English",
    attendance_status: "Present",
    id: "000001",
  },
  {
    name: "Nina Brown",
    attendance_status: "Tardy",
    id: "000001",
  },
  {
    name: "Luis Chavez",
    attendance_status: "Absent",
    id: "000002",
  },
  {
    name: "Sandra Lee",
    attendance_status: "Present",
    id: "000003",
  },
  {
    name: "Mark Miller",
    attendance_status: "Absent",
    id: "000004",
  },
  {
    name: "Zuko Lee",
    attendance_status: "Tardy",
    id: "000005",
  },
];

const GROUPS_DATA = [
  {
    name: "Middle School",
    students: 20,
    overall: "100%",
  },
  {
    name: "High School",
    students: 200,
    overall: "90%",
  },
  {
    name: "ACT Completed",
    students: 15,
    overall: "100%",
  },
];

const PAGINATION = [10, 20, 30];

// LIGHT GREEN - 66BD50
// GREEN - 3B7D2A
// LIGHT ORANGE - FFE3CF
// DARK ORANGE - EF8722
// ERROR - EB0000
// LIGHT RED - FDE6E6
const EventAttendeeList = ({
  appGroups,
  selectedDate,
  searchKeyword,
  data = [],
  groupsSummary = [],
  handleAttendance,
  handleHours,
  // uniqueID,
  // handleSearchUniqueID,
  // handleSeachUnique,
  handleSearch,
  handleSearchSubmit,
  handleDateFilterChange,
  handleFilterByGroup,
  handleSelectAllEvents,
  handleSelectEvent,
  isUniqueIDVisible,
  setIsUniqueIDVisible,
  isEditMode,
  setIsEditMode,
  event
}) => {
  const [attendees, setAttendees] = useState([]);
  const [attendanceSummary, setAttendanceSummary] = useState({
    present: 0,
    late: 0,
    absent: 0,
    presentPercentage: 0,
  });
  const [viewType, setViewType] = useState("individual");
  const [tableViewMode, setTableViewMode] = useState("list");
  const [pagination, setPagination] = useState({
    page: 1,
    offset: 0,
    limit: 10,
    total: 0,
  });
  const [inputClass, setInputClass] = useState("input-search");
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [filteredApplicationList, setFilteredApplicationList] = useState([]);

  const DateCustomInput = ({
    value,
    onClick,
    onChange,
    onClear,
    name,
    className,
    // placeholder,
    label,
  }) => (
    <div className="input-icons">
      <input
        value={value}
        onClick={onClick}
        onChange={onChange}
        name={name}
        className={`${className} input-filter`}
        placeholder={label}
        readOnly={true}
        style={{ width: "100%" }}
      />
      {/* <FontAwesomeIcon icon={faCalendar} className="calendar-icon" style={{ right: 50 }} /> */}
      <img
        onClick={onClear}
        src={value ? ClearIcon : CalendarIcon}
        alt="calendar"
        style={{
          width: 20,
          height: 20,
          position: "absolute",
          right: 20,
          cursor: "pointer",
        }}
      />
      {/* <span>{label}</span> */}
    </div>
  );

  // Handler to change class on focus
  const handleFocus = () => {
    setInputClass("input-search-full");
  };

  // Handler to reset class on blur
  const handleBlur = () => {
    setInputClass("input-search");
  };

  useEffect(() => {
    let initialList = [...data];
    initialList = initialList.splice(0, 10);
    setAttendees([...initialList]);
    setPagination((prevState) => {
      return {
        ...prevState,
        total: Math.ceil(data.length / prevState.limit),
      };
    });
  }, [data]);

  useEffect(() => {
    const countAttendance = (data) => {
      const summary = { present: 0, late: 0, absent: 0, nonattendance: 0 };
      const totalStudent = data.length;
      data.forEach((item) => {
        if (item.attendance_status === "Present") {
          summary.present += 1;
        } else if (item.attendance_status === "Tardy") {
          summary.late += 1;
        } else if (item.attendance_status === "Absent") {
          summary.absent += 1;
        }
        else if (!item.attendance_status) {
          summary.nonattendance += 1;
        }
      });

      const total = summary.present + summary.late + summary.absent + summary.nonattendance;
      let presentPercentage = total ? ((summary.present + summary.late) / total) * 100 : 0;
      presentPercentage = presentPercentage;

      return {
        ...summary,
        presentPercentage: presentPercentage.toFixed(2),
      };
    };

    const summary = countAttendance(data);
    setAttendanceSummary(summary);
  }, [data]);

  const handlePageLimit = (e) => {
    const value = parseInt(e.target.value);
    // const targetEvents = [];
    // const updatedEventList = data.splice(0, value);

    let displayedList = [...data];
    displayedList = displayedList.splice(0, value);

    setAttendees([...displayedList]);

    setPagination((prevState) => {
      return {
        ...prevState,
        page: 1,
        total: Math.ceil(data.length / value),
        limit: value,
      };
    });
  };

  const handlePagination = (type) => () => {
    let currentAttendeeList = [...data];
    let currentPagination = {
      ...pagination,
    };
    if (type === "next") {
      currentPagination.offset =
        currentPagination.offset + currentPagination.limit;
      currentAttendeeList = currentAttendeeList.splice(
        currentPagination.offset,
        currentPagination.limit
      );

      setPagination({
        ...currentPagination,
        page: currentPagination.page + 1,
      });
      setAttendees([...(currentAttendeeList || [])]);
    } else if (type === "prev") {
      currentPagination.offset =
        currentPagination.offset - currentPagination.limit;
      currentAttendeeList = currentAttendeeList.splice(
        currentPagination.offset,
        currentPagination.limit
      );

      setAttendees([...(currentAttendeeList || [])]);
      setPagination({
        ...currentPagination,
        page: currentPagination.page - 1,
      });
    }
  };

  const useStyles = makeStyles((theme) => ({
    customColor: {
      background: "transparent",
    },
  }));

  const classes = useStyles();

  const TooltipCard = () => (
    <div className="tooltip-card">
      <p className="tooltip-text">Attendance</p>
      <p className="tooltip-sub">
        Double-click "late" or "absent" to excuse a student
      </p>

      <div className="status-container-tooltip">
        <div className="flex-status">
          <img src={CircleCheck} alt="Present" width={30} height={30} />
          <p className="tooltip-sub">Present</p>
        </div>

        <div className="flex-status">
          <img src={CircleLate} alt="Late" width={30} height={30} />
          <p className="tooltip-sub">Late</p>
        </div>

        <div className="flex-status">
          <img src={CircleExLate} alt="Excused Late" width={30} height={30} />
          <p className="tooltip-sub">Excused Late</p>
        </div>

        <div className="flex-status">
          <img
            src={CircleExAbsent}
            alt="Excused Absent"
            width={30}
            height={30}
          />
          <p className="tooltip-sub">Excused Absent</p>
        </div>

        <div className="flex-status">
          <img src={CircleAbsent} alt="Absent" width={30} height={30} />
          <p className="tooltip-sub">Absent</p>
        </div>
      </div>
    </div>
  );

  const handleImportAttendanceData = (data) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data provided");
      return;
    }

    let updatedFilteredApplication = [...(attendees || [])];

    updatedFilteredApplication = updatedFilteredApplication
      .map((item) => {
        const currentRecord = data.find(
          (item2) => item2?.unique_id === String(item?.child_id) // Ensure types are the same
        );

        if (currentRecord) {
          const payload = {
            ...item,
            attendance_status: currentRecord.attendance_status,
            mentoring_hours: parseInt(currentRecord.mentoring_hours || 0, 10),
            volunteer_hours: parseInt(currentRecord.volunteer_hours || 0, 10),
          };

          return payload;
        } else {
          return null; // Return null if no match is found
        }
      })
      .filter((item) => item !== null); // Filter out null entries

    if (attendees?.length === 0 || updatedFilteredApplication?.length === 0) {
      setAttendees(data);
    } else {
      setAttendees(updatedFilteredApplication);
    }
  };

  return (
    <EventAttendeeListStyled>
      <div className="attendance-summary-container">
        <div className="summary-container">
          <img src={StudentIcon} className="summary-icon" />
          <div className="summary-details-container">
            <div className="summary-text">Students</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {attendanceSummary?.presentPercentage}% Attendance
            </div>
          </div>
        </div>
        <div className="summary-container">
          <img src={AttendanceCheck} className="summary-icon" />
          <div className="summary-details-container">
            <div className="summary-text">Attendees</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {attendanceSummary?.present}
            </div>
          </div>
        </div>
        <div className="summary-container">
          <img src={AttendanceLate} className="summary-icon" />
          <div className="summary-details-container">
            <div className="summary-text">Late</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {attendanceSummary?.late}
            </div>
          </div>
        </div>
        <div className="summary-container">
          <img src={AttendanceAbsent} className="summary-icon" />
          <div className="summary-details-container">
            <div className="summary-text">Absentees</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {attendanceSummary?.absent}
            </div>
          </div>
        </div>
      </div>

      <div className="attendance-hours-container">
        {/* <div className="summary-container">
          <img src={StudentIcon} className="summary-icon" />
          <div className="summary-details-container">
            <div className="summary-text">Total Number of Mentoring Hours</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {event?.calc_hour_type === 0 ? 'Mentoring' : 'Volunteer'}
            </div>
          </div>
        </div> */}
        <div className="summary-container">
          {/* <img src={StudentIcon} className="summary-icon" /> */}
          <div className="summary-details-container">
            <div className="summary-text">Total Number of Mentoring Hours</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {event?.total_mentoring || 0}
            </div>
          </div>
        </div>
        <div className="summary-container">
          {/* <img src={StudentIcon} className="summary-icon" /> */}
          <div className="summary-details-container">
            <div className="summary-text">Total Number of Volunteer Hours</div>
            <div style={{ fontSize: 22, marginTop: 4 }}>
              {event?.total_volunteer || 0}
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: 400, marginBottom: 12 }}>
        <div className="toggle-list-container">
          <div
            className={`btn-group-type-toggle ${viewType === "individual" ? "btn-group-type-toggle-selected" : ""
              }`}
            onClick={() => {
              setViewType("individual");
            }}
          >
            Individual
          </div>
          <div
            className={`btn-group-type-toggle ${viewType === "groups" ? "btn-group-type-toggle-selected" : ""
              }`}
            onClick={() => {
              setViewType("groups");
            }}
          >
            Groups
          </div>
        </div>
      </div>

      <div className="tooltip-container">
        <Tooltip
          title={<TooltipCard />}
          classes={{ tooltip: classes.customColor }}
        >
          <img
            src={HelpCircle}
            alt="Help"
            id="my-tooltip-1"
            width={24}
            height={24}
            style={{
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </div>

      <div className="filter-container">
        <div>
          {/* <button
            className="import-button"
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
            {`  `}
            Import CSV
          </button> */}
          {/* <CSVLink id="import-button" data={data}>
            <FontAwesomeIcon icon={faDownload} />
            {`  `}
            <span>Import CSV</span>
          </CSVLink> */}
        </div>
        <div className="filter-input-container custom-filter-select">
          {/* <div className="input-icons" style={{ marginRight: "10px" }}>
            <input
              value={searchKeyword}
              onChange={handleSearch}
              onKeyPress={handleSearchSubmit}
              type="text"
              placeholder=""
              className={`input-filter ${inputClass}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <FontAwesomeIcon
              className="icon"
              style={{ right: -10, color: "#6c6c6c", cursor: "pointer" }}
              icon={faSearch}
              onClick={handleFocus}
            />
          </div> */}
          {viewType === "individual" ? (
            <>
              {/* <div style={{ display: "flex", marginLeft: 4 }}>
                <DatePicker
                  value={selectedDate?.from}
                  onChange={handleDateFilterChange("from")}
                  className="input-filter input-date-picker"
                  placeholderText="From"
                  isClearable={true}
                  customInput={
                    <DateCustomInput
                      onClear={() => {
                        handleDateFilterChange("from")(null);
                      }}
                      label="From"
                      className={"date-field"}
                    />
                  }
                />
                <DatePicker
                  value={selectedDate?.to}
                  onChange={handleDateFilterChange("to")}
                  className="input-filter input-date-picker"
                  isClearable={true}
                  placeholderText="To"
                  customInput={
                    <DateCustomInput
                      onClear={() => {
                        handleDateFilterChange("to")(null);
                      }}
                      label="To"
                      className={"date-field"}
                    />
                  }
                />
              </div> */}
              {/* <select
                onChange={handleFilterByGroup}
                placeholder="Filter"
                className="input-filter"
                style={{ width: 120, marginRight: 10 }}
              >
                <option value="">Groups</option>
                {appGroups?.map((item) => {
                  return (
                    <option value={item?.app_grp_id} key={item?.app_grp_id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select> */}
              {/* <select
                className="input-filter"
                style={{ width: 120, marginRight: 10 }}
              >
                <option value="">Filter</option>
              </select> */}
              <div className="password-container">
                <button
                  style={{ backgroundColor: "white", width: 115, marginRight: 5 }}
                  type="button"
                  // type={showPassword ? "text" : "password"}
                  // value={uniqueID}
                  // value="Unique ID"
                  // onChange={handleSearchUniqueID}
                  // onKeyPress={handleSeachUnique}
                  onClick={() => {
                    setIsUniqueIDVisible(!isUniqueIDVisible);
                    // isUniqueIDVisible
                  }}
                  className="input-filter"
                  placeholder="Unique ID"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span>Unique ID</span>
                    <FontAwesomeIcon
                      icon={isUniqueIDVisible ? faEye : faEyeSlash}
                      onClick={() => setIsUniqueIDVisible(!isUniqueIDVisible)}
                      className="password-icon"
                    />
                  </div>

                </button>
                {`  `}
                {!isEditMode && <button
                  style={{
                    backgroundColor: "#FC9A3B",
                    width: 50,
                    fontWeight: "bold",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                  type="button"
                  onClick={() => {
                    setIsEditMode((prevState) => !prevState);
                  }}
                  className="input-filter"
                >
                  Edit
                </button>}

              </div>
            </>
          ) : (
            <>
              <select className="event-input select-filter">
                <option value="">Sort by</option>
              </select>
              <select className="event-input select-filter">
                <option value="">Filter by</option>
              </select>
            </>
          )}

          {/* <ViewToggleButton
            viewMode={tableViewMode}
            setViewMode={setTableViewMode}
          /> */}
        </div>
      </div>
      {viewType === "individual" ? (
        <table id="event-attendee-table">
          <thead className="event-attendee-header">
            <th style={{ width: "35px" }}>
              <input
                onChange={handleSelectAllEvents}
                className="select-all-checkbox"
                type="checkbox"
              />
            </th>
            <th>Student Name</th>
            {/* <th>Group</th> */}
            <th style={{ display: "none" }}>Attendance Rate</th>
            <th>Present</th>
            <th>Late</th>
            <th>Absent</th>
          </thead>

          <tbody>
            {attendees.map((item) => {
              console.log("item: ", item);
              const currentChild = item?.form_contents
                ? getNameFromCustomForm(item.form_contents)
                : {
                  lastname: item?.lastname || "",
                  firstname: item?.firstname || "",
                };
              return (
                <tr>
                  <td style={{ width: "32px" }}>
                    <input
                      checked={item?.selected ? true : false}
                      onChange={handleSelectEvent(item)}
                      className="select-student-checkbox"
                      type="checkbox"
                    />
                  </td>
                  <td style={{ width: 90, textAlign: "center" }}>
                    {currentChild?.firstname && currentChild?.lastname ? (
                      // <a
                      //   className="link-text name-container"
                      //   href={`/dashboard/menteeprofile/${item?.child_id}`}
                      // >
                      //   {currentChild.firstname} {currentChild.lastname}
                      // </a>
                      <span>{currentChild.firstname} {currentChild.lastname}</span>
                    ) : (
                      <div className="empty-name">--</div>
                    )}
                    {isUniqueIDVisible && (
                      <div className="label-unique-id">
                        {item?.new_childId
                          ? item?.new_childId
                          : item?.unique_id}
                      </div>
                    )}
                  </td>
                  {/* <td style={{ width: 250 }}>
                    <span className="event-type-tag">High School</span>
                  </td> */}
                  <td style={{ display: "none" }}>
                    {(item.attendance_status === "Present" || item.attendance_status === "Tardy") ? '100%' : '0%'}
                  </td>
                  <td style={{ width: 120, paddingTop: 0 }}>
                    <div
                      // onClick={() => {
                      //   handleAttendance(item, "Present");
                      // }}
                      className={`attendance-icon ${item.attendance_status === "Present" &&
                        "attendance-present"
                        }`}
                    >
                      {item.attendance_status === "Present" && (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </div>
                  </td>
                  <td style={{ width: 120 }}>
                    <div
                      // onClick={() => {
                      //   handleAttendance(item, "Tardy");
                      // }}
                      className={`attendance-icon ${item.attendance_status === "Tardy" && "attendance-late"
                        }`}
                    >
                      {item.attendance_status === "Tardy" && (
                        <FontAwesomeIcon icon={faMinus} />
                      )}
                    </div>
                    <div className="attendance-excused">
                      {item?.excused === "tardy" ? "Excused" : ""}
                    </div>
                  </td>
                  <td style={{ width: 120 }}>
                    <div
                      // onClick={() => {
                      //   handleAttendance(item, "Absent");
                      // }}
                      className={`attendance-icon ${item.attendance_status === "Absent" &&
                        "attendance-absent"
                        }`}
                    >
                      {item.attendance_status === "Absent" && (
                        <FontAwesomeIcon icon={faTimes} />
                      )}
                    </div>
                    <div className="attendance-excused">
                      {item?.excused === "absent" ? "Excused" : ""}
                    </div>
                  </td>
                </tr>
              );
            })}

            {/* {STUDENT_DATA.map(item => {
                    return <tr>
                        <td style={{ width: "32px" }}>
                            <input
                                className="select-student-checkbox"
                                type="checkbox"
                            />
                        </td>
                        <td style={{ width: 170 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div
                                    className="img-container"
                                >
                                    <img src={UserImg} style={{ width: 45, height: 45 }} />
                                </div>
                                <div className="user-details">
                                    <Link className="link-text" to="#">{item.name}</Link>
                                    <div className="user-id">{item.id}</div>
                                </div>
                            </div>
                        </td>
                        <td style={{ width: 250 }}><span className="event-type-tag">High School</span></td>
                        <td>100.00%</td>
                        <td style={{ width: 150 }}>

                            <div onClick={() => {

                            }} className={`attendance-icon ${item.attendance_status === 'Present' && 'attendance-present'}`}>
                                {item.attendance_status === 'Present' && <FontAwesomeIcon icon={faCheck} />}
                            </div>
                        </td>
                        <td style={{ width: 150 }}>
                            <div onClick={() => {

                            }} className={`attendance-icon ${item.attendance_status === 'Tardy' && 'attendance-late'}`}>
                                {item.attendance_status === 'Tardy' && <FontAwesomeIcon icon={faMinus} />}
                            </div>
                        </td>
                        <td style={{ width: 150 }}>
                            <div className={`attendance-icon ${item.attendance_status === 'Absent' && 'attendance-absent'}`}>
                                {item.attendance_status === 'Absent' && <FontAwesomeIcon icon={faTimes} />}
                            </div>
                        </td>

                    </tr>
                })} */}
          </tbody>
        </table>
      ) : (
        <table id="event-attendee-table">
          <thead className="event-summary-header">
            <th>Groups</th>
            <th>Students</th>
            <th>Overall Attendance</th>
          </thead>
          <tbody>
            {groupsSummary.map((item) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.total_student}</td>
                  <td>
                    {item.present
                      ? ((item.present / item.total_student) * 100).toFixed(2)
                      : 0}
                    %
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 12,
        }}
      >
        <select
          value={pagination.limit}
          onChange={handlePageLimit}
          placeholder="Groups"
          className="input-pagination"
          style={{ width: 130 }}
        >
          {PAGINATION.map((page) => (
            <option value={page}>{page} per page</option>
          ))}
        </select>
        <div className="btn-view-page-container" style={{ marginLeft: 12 }}>
          <span style={{ paddingLeft: 5, fontSize: 14 }}>
            {pagination.page} of {pagination.total}
          </span>
          <button
            type="button"
            disabled={!pagination.offset}
            onClick={handlePagination("prev")}
            style={{ border: "none" }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            disabled={pagination.page === pagination.total}
            onClick={handlePagination("next")}
            style={{ border: "none" }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <ModalImport
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        onSave={handleImportAttendanceData}
      />
    </EventAttendeeListStyled>
  );
};

export default EventAttendeeList;
