import React, { useState, useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { useForm } from "react-hook-form";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlus, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "../../../helpers/ErrorMessage";
import Recaptcha from "react-recaptcha";

const LoginFormStyled = styled.form`
  .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 20px;
  }
  .input-item {
    display: flex;
    flex-direction: column;
  }
  .input-item-password {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }
  label {
    color: ${({ theme }) => theme.label.textColor.primary};
    font-size: ${({ theme }) => theme.label.fontSize};
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }
  input:required {
    box-shadow: none;
  }
  input:invalid {
    box-shadow: none;
  }
  input {
    background: #fbfbfb;
    width: auto;
    color: black;
    font-size: ${({ theme }) => theme.input.fontSize};
    border: none;
    border-radius: 8px;
    border: 1px solid #cacacb;
    outline: 0;
    padding: 8px 16px;
    margin-bottom: 10px;
    height: 30px;
  }
  input:focus {
    border-color: ${({ theme }) => theme.input.focus.border.color};
    transition: 3s;
  }
  .text-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  button {
    color: ${({ theme }) => theme.button.textColor.neutralBlack};
    font-size: 18px;
    font-weight: 600;
    border: none;
    padding-top: 4em;
    padding-bottom: 1em;
    border-radius: ${({ theme }) => theme.button.borderRadius} !important;
  }
  button[type="submit"] {
    padding: 10px;
    display: block;
    margin: 48px auto 20px;
    background-color: #fc9a3b !important;
    border: none;
    width: 292px !important;
    height: 40px !important;
    cursor: pointer;
  }
  button[type="submit"]:disabled {
    background-color: #fdcc9d !important;
    cursor: not-allowed;
    color: #877f7b;
  }
  .bottom-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom-link p {
    color: #6c6c6c !important;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
  .bottom-link a {
    color: #1b0b03 !important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  }
  svg {
    vertical-align: middle;
  }
  #socials {
    margin-top: 2em !important;
  }
  #socials button {
    padding: 1em;
    border-radius: 0 !important;
    margin: 5px;
  }
  #facebook {
    background-color: ${({ theme }) => theme.button.backgroundColor.secondary};
  }
  #google {
    background-color: ${({ theme }) => theme.button.backgroundColor.error};
  }
  #authOptions {
    margin-left: auto !important;
  }
  #authOptions p {
    font-weight: bold;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.p.fontSize} !important;
  }
  #authOptions p a {
    color: ${({ theme }) => theme.anchor.textColor.secondary};
    font-size: 14px !important;
    font-weight: 400;
    // font-size: ${({ theme }) => theme.anchor.fontSize} !important;
    text-decoration: none;
  }
  #socials > * {
    width: initial;
  }
  p.error {
    text-align: left !important;
  }
  p.error-size {
    font-size: 14px !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  .password-container {
    display: flex;
    width: 100%;
  }

  .input-password {
    position: relative;
    width: 100%;
    padding-right: 2.5rem;
  }

  .password-icon {
    position: absolute;
    right: 3rem;
    transform: translateY(90%);
    cursor: pointer;
    color: #6c6c6c;
  }

  @media (min-width: 600px) {
    .grid {
      grid-template-columns: 50% 50%;
      grid-gap: 1%;
    }
    #authOptions p:first-child {
      text-align: left;
    }
    #authOptions p:last-child {
      text-align: right;
    }
    button[type="submit"] {
      width: 300px;
    }
  }
  #g-recaptcha {
    margin-top: 1em;
  }
`;

const isStaging = process.env.APP_ENVIRONMENT === "STAGE";

export default function Form({
  userDetails,
  onSubmit,
  handleInputChange,
  // handleGoogleSignIn,
  // handleFacebookSignIn,
  hideForgotPassword = false,
}) {
  const theme = useContext(ThemeContext);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onVerifyCaptcha = (response) => {
    if (response && !isStaging) {
      setValue("not_robot", response);
    }
  };

  useEffect(() => {
    if (!isStaging) {
      register({ name: "not_robot" }, { required: true });
    }
  }, []);

  return (
    <LoginFormStyled
      theme={theme}
      data-testid="app-login-form"
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="input-group">
        <div className="input-item">
          <label>Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            data-testid="app-login-input-email"
            placeholder="Email"
            onChange={({ target }) => {
              handleInputChange("email", target.value);
            }}
            ref={register({ required: true })}
          />
          <ErrorMessage
            className="error-size"
            field={errors.email}
            errorType="required"
            message="Enter a valid email address "
          />
        </div>
        <div className="input-item-password">
          <label>Password</label>
          <div className="password-container">
            <input
              className="input-password"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              data-testid="app-login-input-password"
              placeholder="Password"
              onChange={({ target }) => {
                handleInputChange("password", target.value);
              }}
              ref={register({ required: true })}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={() => setShowPassword(!showPassword)}
              className="password-icon"
            />
          </div>
          <div className="text-container">
            <ErrorMessage
              className="error-size"
              field={errors.password}
              errorType="required"
              message="Your password is incorrect."
            />
            <div id="authOptions">
              {/* <p>
          New Member! <Link to="/auth/create">Sign Up</Link>
        </p> */}
              {hideForgotPassword ? (
                ""
              ) : (
                <p>
                  <Link to="/auth/forgot-password">Forgot Password?</Link>
                </p>
              )}
            </div>
          </div>
        </div>

        {!isStaging && (
          <>
            <Recaptcha
              sitekey={`${process.env.RECAPTCHA_KEY}`}
              render="explicit"
              verifyCallback={onVerifyCaptcha}
            />
            <ErrorMessage
              className="error-size"
              field={errors.not_robot}
              errorType="required"
              message="Please verify that you are not a robot."
            />
          </>
        )}

        <button
          type="submit"
          data-testid="app-login-button-signin"
          disabled={!userDetails?.email || !userDetails?.password}
        >
          Sign In
        </button>
        {/* 
          HIDE FOR NOW
          <div className="bottom-link">
            <p>
              Don't have an account?{" "}
              <span>
                <Link to="/auth/create">Create Account</Link>
              </span>
            </p>
          </div> 
        */}
        
        {/* START Temporary comment as of august 28 */}
        {/* <div id="socials" className="grid">
        <button
          id="facebook"
          type="button"
          onClick={() => {
            handleFacebookSignIn();
          }}
        >
          <FontAwesomeIcon icon={faFacebook} fixedWidth size="2x" />
          <span> Sign In with Facebook</span>
        </button>
        <button
          id="google"
          type="button"
          onClick={() => {
            handleGoogleSignIn();
          }}
        >
          <FontAwesomeIcon icon={faGooglePlus} fixedWidth size="2x" />
          <span> Sign In with Google</span>
        </button>
      </div> */}
        {/* END Temporary comment as of august 28 */}
        {/*className="grid" */}
      </div>
    </LoginFormStyled>
  );
}
