import React, { useRef, useState } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
import CSVUpload from "../images/csv-upload.svg";
import ExcelIcon from "../images/excel-icon.svg";
import ExcelDisabled from "../images/excel-disabled.svg";
import UploadTrash from "../images/trash-upload.svg";
import UploadClose from "../images/upload-close.svg";

const ModalContainerStyled = styled.div`
.modal-container {
  padding: 20 px !important;
  width: 100%;
}
`;



const ModalContent = styled.div`
.h2-style {
  font-size: 20px !important;
  font-weight: 700px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
//   Newly added
.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.border-left-0 {
  border-left-width: 0px !important;
}

.border-right-0 {
  border-right-width: 0px !important;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: px;
}

.mb-10 {
  margin-bottom: 10px;
}

.gap-2 {
  gap: 2px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-6 {
  gap: 6px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-40 {
  gap: 40px !important;
}

.gap-50 {
  gap: 50px !important;
}

.tabs-container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
}

.tabs-import {
  color: #1b0b03;
  border-radius: 2rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 2rem;
  margin-bottom: 0 !important;
  gap: 15px !important;
}

.react-tabs__tab {
  font-size: 12px;
  font-weight: 600;
  width: 50%;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  bottom: 0px !important;
  background: #f4f4f5;
  border: 1px solid #cacacb;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  white-space: nowrap;
}

.react-tabs__tab--selected {
  background: #1b0b03;
  color: #fff;
}

.react-tabs__tab--disabled {
  background: #f4f4f5 !important;
  font-size: 16px;
  font-weight: 400;
  color: #1b0b03;
  cursor: default;
}

.react-tabs__tab:hover {
  background: #1b0b03;
  color: #fff;
}

// .react-tabs__tab:first-child {
//   border-bottom-left-radius: 2rem;
//   border-top-left-radius: 2rem;
//   border-top-right-radius: 2rem;
//   border-bottom-right-radius: 2rem;
// }

// .react-tabs__tab:nth-child(2) {
//   border-bottom-left-radius: 2rem;
//   border-top-left-radius: 2rem;
//   border-top-right-radius: 2rem;
//   border-bottom-right-radius: 2rem;
// }

// .react-tabs__tab:last-child {
//   border-bottom-right-radius: 2rem;
//   border-top-right-radius: 2rem;
//   border-bottom-left-radius: 2rem;
//   border-top-left-radius: 2rem;
// }

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-size: 2rem;
  text-align: center;
}

.upload-container {
  display: flex;
  width: 300px;
  height: 150px;
  padding: 32px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 2px dashed #cacacb;
  background: #fff;
  margin-top: 24px;
}

.upload-text {
  font-size: 14px;
  font-weight: 600;
  color: #1b0b03;
  margin-top: 0;
  margin-bottom: 4px;
}

.upload-sub {
  font-size: 12px;
  font-weight: 400;
  color: #6c6c6c;
  margin-top: 0;
  margin-bottom: 4px;
}

.upload-subText {
  font-size: 12px;
  font-weight: 600;
  color: #6c6c6c;
  margin-top: 0;
  margin-bottom: 4px;
}

.upload-btn {
  display: flex;
  padding: 4px 24px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #fc9a3b;
  background: #fdf0e7;
  margin-bottom: 4px;
}

.upload-sub-title {
  font-size: 16px;
  font-weight: 600;
  color: #1b0b03;
  margin-top: 0;
  margin-bottom: 24px;
}

.uploaded-files {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 150px;
  margin-top: 24px;
}

.upload-btn-submit {
  display: flex;
  height: 40px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #dedede;
  border: none;
}

.upload-files-disabled {
  font-size: 12px;
  font-weight: 600;
  color: #1b0b03;
  margin-top: 0;
  margin-bottom: 4px;
}

.upload-size-disabled {
  font-size: 10px;
  font-weight: 400;
  color: #6c6c6c;
  margin-top: 0;
  margin-bottom: 4px;
}

.uploaded-size-container {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
}

.progress-container {
  width: 200px;
  height: 10px;
}

.container-progress {
  height: 10px;
  background: rgb(224, 224, 222);
  border-radius: 50px;
  width: 100%;
  overflow: hidden;
  padding-top: 0 !important;
}

.barCompleted {
  height: 10px;
  width: 60%;
  background: rgb(60, 60, 60);
  transition: width 1s ease-in-out 0s;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bar-text {
  font-size: 12px
  font-weight: 600;
  color: #3C3C3C;
  margin-top: 0;
  margin-bottom: 0;
}
`;


const ModalImport = ({ openModal, onCloseModal, onSave }) => {

  const fileRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(null);



  const removeCarriageReturn = (inputString = '') => {
    return inputString.replace(/\r/g, '');
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('file', file)

    let reader = new FileReader()
    reader.onloadend = function (e) {
      const rows = e.target.result.split("\n");

      if (rows.length > 0) {
        console.log('formattedRows 22',rows)
        let formattedRows =  rows.map(row => removeCarriageReturn(row));
   
        formattedRows = formattedRows.map(row => row.split(','))
        // const importedColumns = formattedRows[0]; // columns
        let importedData = formattedRows.slice(1); // data from csv

        importedData = importedData.map(item => {
          return {
            unique_id: item[0],
            firstname: item[1],
            lastname: item[2],
            attendance_status: item[3],
            volunteer_hours: item[4],
            mentoring_hours: item[5],

          }
        })
        setData([...(importedData || [])]);

      }
    };

    reader.readAsText(file);
    setSelectedFile(file);
  };

  const getSizeInMB = bytes => {
    return (bytes / (1024 * 1024)).toFixed(4);
  }


  const save = () => {
    setSelectedFile(null);
    onSave(data);
    onCloseModal();
    setData([]);
  }
  return (
    <ModalContainerStyled>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        styles={{
          modal: {
            width: "600px",
            padding: "30px",
            borderRadius: "8px",
          },
        }}
      >
        <ModalContent>
          <h2 className="h2-style">Upload CSV</h2>

          <div className="tabs-container">
            <Tabs className="tabs-import">
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2</Tab>
                <Tab>Day 3</Tab>
                <Tab>Day 4</Tab>
                <Tab>Day 5</Tab>
                <Tab>Day 6</Tab>
                <Tab>Day 7</Tab>
              </TabList>
            </Tabs>
          </div>

          <div className="flex items-start justify-between gap-24">
            <div className="upload-container">
              <img
                src={CSVUpload}
                alt="csv upload"
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />

              <div className="flex items-center flex-column">
                <p className="upload-text">Drag and drop files to upload</p>
                <p className="upload-sub">or</p>

                <button onClick={() => {
                  fileRef.current.click();
                }} type="button" className="upload-btn">
                  Browse Files
                </button>

                <input onChange={handleFileChange} ref={fileRef} type="file" style={{ display: "none" }} />

                <p className="upload-sub">
                  Max File: <span className="upload-subText">5MB</span>
                </p>
              </div>
            </div>

            <div className="uploaded-files">
              <p className="upload-sub-title">Uploaded Files</p>


              {selectedFile && <div className="uploaded-size-container">
                <div className="flex items-center justify-between gap-50">
                  <div className="flex items-center gap-6">
                    <img
                      src={ExcelIcon}
                      alt="excel disabled"
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    <div>
                      <p className="upload-files-disabled">
                        {selectedFile?.name}
                      </p>
                      <p className="upload-size-disabled">Excel {getSizeInMB(selectedFile?.size)}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                      src={UploadTrash}
                      alt="delete"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </div>
                </div>
              </div>}



              {/* <div className="uploaded-size-container">
                <div className="flex items-center gap-6">
                  <img
                    src={ExcelDisabled}
                    alt="excel disabled"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <div>
                    <p className="upload-files-disabled">
                      chi-tech_conference_2024
                    </p>
                    <p className="upload-size-disabled">Excel 8.5MB</p>
                  </div>
                </div>
                <div className="flex items-center gap-10">
                  <div className="flex items-center gap-6">
                    <ProgressBar
                      completed={60}
                      bgColor="#3C3C3C"
                      className="progress-container"
                      barContainerClassName="container-progress"
                      completedClassName="barCompleted"
                      isLabelVisible={false}
                    />
                    <p className="bar-text">50%</p>
                  </div>
                  <img
                    src={UploadClose}
                    alt="close"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </div>
              </div> */}
              <div className="hidden">
                <p className="upload-sub">No Files Uploaded</p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center gap-8 mt-20">
            <button
              type="button"
              className="upload-btn-submit"
              onClick={onCloseModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="upload-btn-submit"
              onClick={save}
            >
              Save
            </button>
          </div>
        </ModalContent>
      </Modal>
    </ModalContainerStyled >
  );
};

export default ModalImport;
