module.exports = [
  {
    "label": "Date",
    "type": "date",
    "fields": [
      {
        "label": "MM",
        "type": "text",
        "tag": "input",
        "placeholder": "MM",
        "id": "input_85ef86a3-9591-489f-bc22-8bf900f6f8ef",
        "value": ""
      },
      {
        "label": "DD",
        "type": "text",
        "tag": "input",
        "placeholder": "DD",
        "id": "input_85ef86a3-9591-489f-bc22-8bf900f6f8ef",
        "value": ""
      },
      {
        "label": "YYYY",
        "type": "text",
        "tag": "input",
        "placeholder": "YYYY",
        "id": "input_85ef86a3-9591-489f-bc22-8bf900f6f8ef",
        "value": ""
      },
      {
        "label": "",
        "type": "date",
        "tag": "icon",
        "placeholder": "date",
        "id": "icon_85ef86a3-9591-489f-bc22-8bf900f6f8ef",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "85ef86a3-9591-489f-bc22-8bf900f6f8ef",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": false
  },
  {
    "label": "Email",
    "type": "email",
    "fields": [
      {
        "label": "Type",
        "type": "type",
        "tag": "select",
        "placeholder": "Type",
        "requireAddOption": true,
        "options": [
          {
            "name": "Personal",
            "label": "Personal"
          },
          {
            "name": "Work",
            "label": "Work"
          }
        ],
        "id": "select_ca25b75c-48fc-4ac5-85ac-30b505fd19ec",
        "value": ""
      },
      {
        "label": "Email",
        "type": "email",
        "tag": "input",
        "placeholder": "sample@email.com",
        "id": "input_ca25b75c-48fc-4ac5-85ac-30b505fd19ec",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "ca25b75c-48fc-4ac5-85ac-30b505fd19ec",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": false
  },
  {
    "label": "Time",
    "type": "time",
    "fields": [
      {
        "label": "Time",
        "type": "text",
        "tag": "input",
        "placeholder": "Time",
        "id": "input_412609c4-2bd8-4ddc-a052-8afd99c52a86",
        "value": ""
      },
      {
        "label": "",
        "type": "time",
        "tag": "icon",
        "placeholder": "time",
        "id": "icon_412609c4-2bd8-4ddc-a052-8afd99c52a86",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "412609c4-2bd8-4ddc-a052-8afd99c52a86",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": false
  },
  {
    "label": "Phone",
    "type": "phone",
    "fields": [
      {
        "label": "Type",
        "type": "type",
        "tag": "select",
        "placeholder": "Type",
        "requireAddOption": true,
        "options": [
          {
            "name": "Cell",
            "label": "Cell"
          },
          {
            "name": "Home",
            "label": "Home"
          },
          {
            "name": "Work",
            "label": "Work"
          }
        ],
        "id": "select_751deb8e-4316-4d34-a759-93bdc5b81ef6",
        "value": ""
      },
      {
        "label": "Phone",
        "type": "text",
        "tag": "input",
        "placeholder": "###-###-####",
        "id": "input_751deb8e-4316-4d34-a759-93bdc5b81ef6",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "751deb8e-4316-4d34-a759-93bdc5b81ef6",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": false
  },
  {
    "label": "Website",
    "type": "website",
    "fields": [
      {
        "label": "Website",
        "type": "text",
        "tag": "input",
        "placeholder": "Website",
        "column": "1",
        "id": "input_1f0d9871-827f-4b13-8e69-0b076cca42bc",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "1f0d9871-827f-4b13-8e69-0b076cca42bc",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": false
  },
  {
    "label": "Price",
    "type": "price",
    "fields": [
      {
        "label": "",
        "type": "currency",
        "tag": "icon",
        "placeholder": "currency",
        "id": "icon_fe55f8af-8968-4c56-b091-53ff177b04da",
        "value": ""
      },
      {
        "label": "Price",
        "type": "text",
        "tag": "input",
        "placeholder": "Price",
        "id": "input_fe55f8af-8968-4c56-b091-53ff177b04da",
        "value": ""
      }
    ],
    "groupType": "prime",
    "settings": {},
    "id": "fe55f8af-8968-4c56-b091-53ff177b04da",
    "allowAddField": false,
    "includeLogic": false,
    "includeValidation": false,
    "gridMax": 3,
    "hasSettings": true,
    "supportMultiple": false,
    "isActive": true
  }
];