import gql from "graphql-tag";

export const GET_EVENT_QUERY = gql`
  query Events($email: String!) {
    getEvents(email: $email) {
      id
      calendar_id
      name
      description
      status
      type
      start_of_event
      end_of_event
      location
      user_id
      color
      visibility
      recurring
      recurring_end_date
      group_ids
      app_group_ids
      allowed_edit
      guests {
        email
        status
        user_id
        event_id
        profile_img
      }
    }
  }
`;

export const GET_EVENT_INVITATION_QUERY = gql`
  query Events($email: String!) {
    getEventInvitedUser(email: $email) {
      id
      name
      status
      user_id
    }
  }
`;

// add by joemari
export const GET_EVENT_BY_CHILDID_QUERY = gql`
  query getEventByIdAndChild($event_id: String, $child_id: String) {
    getEventByIdAndChild(event_id: $event_id, child_id: $child_id) {
      event {
        id
        vendor_id2
        event_type
        title
        start
        end
        is_full_day
        vendor_app_group
        tags
        description
        qr_code_url
        location
        attendance_type
        attendance_app_group
        city
        state
        repeat_Type
        repeat_On
        event_ends
        vendor_name
        vendor_firstname
        vendor_lastname
      }
      attendance {
        app_id
        attendance_status
        child_id
        vendor
        mentoring_hours
        volunteer_hours
        is_excused
        is_following
        attendance_type
        attendance_date
        attendance_start_time
        attendance_end_time
        app_group_id
      }
    }
  }
`;

/*     description
      status
      type
      start_of_event
      end_of_event
      location
      */
